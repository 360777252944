/* global axios */
import ApiClient from './ApiClient';

class Connectwise extends ApiClient {
  constructor() {
    super('connectwise', { accountScoped: true });
  }

  getCompanies() {
    return axios.get(`${this.url}/companies`);
  }

  getBoards() {
    return axios.get(`${this.url}/boards`);
  }

  getContacts(id) {
    return axios.get(`${this.url}/contacts?id=${id}`);
  }

  getSites(id) {
    return axios.get(`${this.url}/${id}/sites/`);
  }

  getTypes(id) {
    return axios.get(`${this.url}/${id}/types/`);
  }

  getStatuses(id) {
    return axios.get(`${this.url}/${id}/statuses/`);
  }

  createTicket(postData, id) {
    return axios.post(`${this.url}/create_ticket/`, { postData, id });
  }

  searchTickets(companyId, ticket) {
    return axios.post(`${this.url}/search_ticket/`, { companyId, ticket });
  }

  mergeTicket(choosedTicket, chatId) {
    return axios.post(`${this.url}/merge_ticket/`, {
      choosed_ticket: choosedTicket,
      chat_id: chatId,
    });
  }
}

export default new Connectwise();
