import types from '../../mutation-types';
import Connectwise from '../../../api/connectwise';

export const actions = {
  companies: async ({ commit }) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingCompanies: true });
    try {
      const response = await Connectwise.getCompanies();
      commit(types.SET_COMPANIES, response.data.data);
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingCompanies: false });
    } catch {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingCompanies: false });
    }
  },
  boards: async ({ commit }) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingBoards: true });
    try {
      const response = await Connectwise.getBoards();
      commit(types.SET_BOARDS, response.data.data);
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingBoards: false });
    } catch {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingBoards: false });
    }
  },
  contacts: async ({ commit }, companyId) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingContacts: true });
    try {
      const response = await Connectwise.getContacts(companyId);
      const updatedContacts = response.data.data.map(contact => {
        return {...contact, fullName: `${contact.firstName} ${contact.lastName}`};
      });
      commit(types.SET_CONTACTS, updatedContacts);
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingContacts: false });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingContacts: false });
    }
  },
  sites: async ({ commit }, companyId) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingSites: true });
    try {
      const response = await Connectwise.getSites(companyId);
      commit(types.SET_SITES, response.data.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingSites: false });
    }
  },
  types: async ({ commit }, boardId) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingTypes: true });
    try {
      const response = await Connectwise.getTypes(boardId);
      commit(types.SET_TYPES, response.data.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingTypes: false });
    }
  },
  statuses: async ({ commit }, boardId) => {
    commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingStatuses: true });
    try {
      const response = await Connectwise.getStatuses(boardId);
      commit(types.SET_STATUSES, response.data.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONNECTWISE_UI_FLAG, { isFetchingStatuses: false });
    }
  },
};
