<template>
  <section class="clients-users-table-wrap">
    <h3 class="client-users">Users</h3>
    <ve-table
      v-if="!uiFlags.isFetchingUsers && users.length"
      :fixed-header="true"
      width="100vw"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
    />
    <table-footer
      v-if="!uiFlags.isFetchingUsers && users.length"
      :current-page="Number(meta.currentPage)"
      :total-count="meta.count"
      :page-size="meta.pageSize"
      @page-change="onPageChange"
    />
    <empty-state
      v-if="!uiFlags.isFetchingUsers && !users.length"
      :title="'No available users'"
    />
    <div v-if="uiFlags.isFetchingUsers" class="users--loader">
      <spinner />
      <span>Loading Users</span>
    </div>
  </section>
</template>
<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import UserMenu from './UserMenu.vue';

export default {
  components: {
    VeTable,
    Spinner,
    EmptyState,
    TableFooter,
    // eslint-disable-next-line vue/no-unused-components
    UserMenu,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manageUser: '',
    };
  },
  computed: {
    ...mapGetters({
      users: 'clients/getUsers',
      uiFlags: 'clients/getUIFlags',
      meta: 'clients/getUsersMeta',
    }),
    tableData() {
      if (this.uiFlags.isFetchingUsers) {
        return [];
      }
      return this.users.map(item => {
        // Note: The attributes used here is in snake case
        // as it simplier the sort attribute calculation
        return {
          name: item.name,
          source: item.source,
          status:
            item.status === 'blocked_via_office_365'
              ? item.status
              : `${item.status}d`,
          email: item.email,
          id: item.id,
        };
      });
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: '',
          fixed: 'left',
          align: 'left',
          width: '40%',
          renderBodyCell: ({ row }) => (
            <div class="row--user-block">
              <div class="user-block">
                <h6 class="sub-block-title text-truncate">{row.name}</h6>
                <h6 class="sub-block-title text-truncate">{row.email}</h6>
              </div>
            </div>
          ),
        },
        {
          field: 'source',
          key: 'source',
          title: 'Source',
          align: 'left',
          width: '20%',
        },
        {
          field: 'status',
          key: 'status',
          title: 'Status',
          align: 'left',
          width: '25%',
          renderBodyCell: ({ row }) => {
            if (row.status === 'blocked_via_office_365') {
              return (
                <div class="status">
                  {row.status.replaceAll('_', ' ')}
                  <p
                    v-tooltip="
                      This user has been blocked via Office 365. This user cannot have access to Zerotouch Chat until user is no longer blocked via Office 365 portal.
                    "
                  >
                    <fluent-icon icon="info" />
                  </p>
                </div>
              );
            }
            return <div class="status">{row.status.replaceAll('_', ' ')}</div>;
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: '',
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="user-menu-wrapper">
              <woot-button
                colorScheme="dark"
                variant="smooth"
                is-disabled={row.status === 'blocked_via_office_365'}
                onClick={() => this.onClickManageUser(row.id)}
              >
                Manage
                <fluent-icon icon="caret" size="12" />
              </woot-button>
              <user-menu
                show={row.id === this.manageUser}
                close={() => this.hideManage()}
                user={row}
              />
            </div>
          ),
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch('clients/getUsers', { id: this.clientId, page: 1 });
  },
  methods: {
    onPageChange(page) {
      this.$store.dispatch('clients/getUsers', { id: this.clientId, page });
    },
    onClickManageUser(id) {
      this.manageUser = id;
    },
    hideManage() {
      this.manageUser = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-table .ve-table-container {
  overflow: auto;
  height: 100%;
  width: 100%;
}
.users--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}
.button {
  margin-left: var(--space-small);
  height: 3.2rem;
  padding: 0 var(--space-small);
  transition: transform 100ms linear;
  transform: translateX(-1px);
  a {
    color: white;
  }
  svg {
    font-size: 8px;
    margin-left: 2px;
  }
}
.status {
  text-transform: capitalize;
  justify-content: unset;

  p > svg {
    color: var(--w-500);
  }
}
.user-menu-wrapper {
  position: relative;
}
.client-users {
  padding-left: 10px;
}
</style>
<style lang="scss">
.clients-users-table-wrap .ve-table-container {
  overflow-y: unset !important;
}
</style>
