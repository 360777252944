<template>
  <aside class="woot-sidebar">
    <div class="sidebar-menu" :class="sidebarClassName">
      <span class="close" @click="toggleSidebar" />
      <el-col class="sidebar-menu-list-wrapper" :span="24">
        <el-menu
          class="el-menu-vertical-demo"
          @open="transform"
          @close="transform"
        >
          <el-submenu
            v-for="(primaryMenu, primaryIdx) in primaryMenuItems"
            :id="`${primaryIdx}`"
            :key="primaryIdx"
            :index="primaryIdx.toString()"
            :class="grayOut(primaryMenu)"
          >
            <template
              v-if="isOnDesktop || primaryMenu.key === 'conversations'"
              slot="title"
            >
              <font-awesome-icon
                :id="`arrow-icon-${primaryIdx}`"
                icon="fa-solid fa-play"
                class="initial"
              />
              <fluent-icon :icon="primaryMenu.icon" />
              <span>{{ $t(`SIDEBAR.${primaryMenu.label}`) }}</span>
            </template>
            <template
              v-for="(secondaryMenu, secondaryIdx) in secondaryMenuItems"
            >
              <el-menu-item-group
                v-if="primaryMenu.key == secondaryMenu.parentNav"
                :key="secondaryIdx"
              >
                <el-menu-item
                  v-for="(menuItem, menuIdx) in secondaryMenu.menuItems"
                  :key="menuIdx"
                  :index="menuIdx.toString()"
                  :class="grayOutSubMenu(menuItem)"
                  @click="changeRoute(`link-${secondaryIdx}-${menuIdx}`)"
                >
                  <router-link
                    v-if="
                      menuItem.label != 'AGENT_BOTS' &&
                        menuItem.label != 'BILLING'
                    "
                    :id="`link-${secondaryIdx}-${menuIdx}`"
                    :to="menuItem.toState"
                    @click.native.stop="toggleSidebar"
                  >
                    <fluent-icon :icon="menuItem.icon" />
                    <span>
                      {{ $t(`SIDEBAR.${menuItem.label}`) }}
                    </span>
                  </router-link>
                </el-menu-item>
              </el-menu-item-group>
            </template>
            <el-menu-item-group
              v-if="primaryMenu.key === 'conversations'"
              title="Teams"
            >
              <el-menu-item
                v-for="(team, teamIdx) in teams"
                :key="team.id"
                @click="changeRoute(`teamlink-${teamIdx}`)"
              >
                <router-link
                  :id="`teamlink-${teamIdx}`"
                  :to="`/app/accounts/${accountId}/team/${team.id}`"
                  @click.native.stop="toggleSidebar"
                >
                  <span>{{ team.name }}</span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                v-if="
                  primaryMenu.key === 'conversations' && currentRole !== 'agent'
                "
                @click="changeRoute(`teamlink`)"
              >
                <router-link
                  v-if="isOnDesktop"
                  id="teamlink"
                  :to="`/app/accounts/${accountId}/settings/teams/new`"
                  @click.native.stop="toggleSidebar"
                >
                  <span>+ New team</span>
                </router-link>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group
              v-if="primaryMenu.key === 'conversations'"
              title="Inboxes"
            >
              <el-menu-item
                v-for="(inbox, inboxIdx) in inboxes"
                :key="inbox.id"
                @click="changeRoute(`inboxlink-${inboxIdx}`)"
              >
                <router-link
                  :id="`inboxlink-${inboxIdx}`"
                  :to="`/app/accounts/${accountId}/inbox/${inbox.id}`"
                  @click.native.stop="toggleSidebar"
                >
                  <fluent-icon
                    :class="computedInboxClass(inbox)"
                    :icon="computedInboxClass(inbox)"
                    size="12"
                  />
                  <span>{{ inbox.name }}</span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                v-if="
                  primaryMenu.key === 'conversations' && currentRole !== 'agent'
                "
                @click="changeRoute(`inboxlink`)"
              >
                <router-link
                  v-if="isOnDesktop"
                  id="inboxlink"
                  :to="`/app/accounts/${accountId}/settings/inboxes/new`"
                  @click.native.stop="toggleSidebar"
                >
                  <span>+ New inbox</span>
                </router-link>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group
              v-if="
                primaryMenu.key === 'conversations' ||
                  primaryMenu.key === 'contacts'
              "
              title="Labels"
            >
              <el-menu-item
                v-for="(label, labelIdx) in labels"
                :key="label.title"
                @click="changeRoute(`labellink-${labelIdx}`)"
              >
                <router-link
                  :id="`labellink-${labelIdx}`"
                  :to="
                    primaryMenu.key === 'conversations'
                      ? `/app/accounts/${accountId}/label/${label.title}`
                      : `/app/accounts/${accountId}/labels/${label.title}/contacts`
                  "
                  @click.native.stop="toggleSidebar"
                >
                  <span
                    class="badge--label"
                    :style="{ backgroundColor: label.color }"
                  />
                  <span>{{ label.title }}</span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                v-if="
                  (primaryMenu.key === 'conversations' ||
                    primaryMenu.key === 'contacts') &&
                    currentRole !== 'agent'
                "
                @click="changeRoute(`labellink`)"
              >
                <router-link
                  v-if="isOnDesktop"
                  id="labellink"
                  :to="`/app/accounts/${accountId}/settings/labels`"
                  @click.native.stop="toggleSidebar"
                >
                  <span>+ New label</span>
                </router-link>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div v-if="!isOnDesktop" class="non-desktop-msg" hidden>
          <fluent-icon icon="warning" class="warning-icon" />
          <p>
            {{ $t('SIDEBAR.NON_DESKTOP.DISPLAY_TEXT') }}
          </p>
          <p>{{ $t('SIDEBAR.NON_DESKTOP.LIMITED_MESSAGE') }}</p>
          <p>{{ $t('SIDEBAR.NON_DESKTOP.FUNCTIONALITY_MESSAGE') }}</p>
        </div>
      </el-col>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../mixins/isAdmin';
import { getSidebarItems } from './config/default-sidebar';
import alertMixin from 'shared/mixins/alertMixin';

import {
  hasPressedAltAndCKey,
  hasPressedAltAndRKey,
  hasPressedAltAndSKey,
  hasPressedAltAndVKey,
  hasPressedCommandAndForwardSlash,
  isEscape,
} from 'shared/helpers/KeyboardHelpers';
import { getInboxClassByType } from '../../helper/inbox';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import router from '../../routes';

export default {
  mixins: [adminMixin, alertMixin, eventListenerMixins],
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
    sidebarClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isRotate: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      isOnZerotouchChatCloud: 'globalConfig/isOnZerotouchChatCloud',
      labels: 'labels/getLabelsOnSidebar',
      teams: 'teams/getMyTeams',
      isOnDesktop: 'layout/isOnDesktop',
    }),
    activeCustomView() {
      if (this.activePrimaryMenu.key === 'contacts') {
        return 'contact';
      }
      if (this.activePrimaryMenu.key === 'conversations') {
        return 'conversation';
      }
      return '';
    },

    customViews() {
      return this.$store.getters['customViews/getCustomViewsByFilterType'](
        this.activeCustomView
      );
    },
    isConversationOrContactActive() {
      return (
        this.activePrimaryMenu.key === 'contacts' ||
        this.activePrimaryMenu.key === 'conversations'
      );
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    secondaryMenuItems() {
      const secondaryMenus = this.sideMenuConfig.secondaryMenu;

      secondaryMenus.forEach(secondaryMenu => {
        secondaryMenu.menuItems = secondaryMenu.menuItems.filter(
          route =>
            window.roleWiseRoutes[this.currentRole].indexOf(route.toStateName) >
            -1
        );
      });
      return secondaryMenus;
    },
    primaryMenuItems() {
      const menuItems = this.sideMenuConfig.primaryMenu;
      return menuItems.filter(menuItem => {
        const isAvailableForTheUser = menuItem.roles.includes(this.currentRole);

        if (!isAvailableForTheUser) {
          return false;
        }

        if (menuItem.featureFlag) {
          return this.isFeatureEnabledonAccount(
            this.accountId,
            menuItem.featureFlag
          );
        }
        return true;
      });
    },
    activeSecondaryMenu() {
      const { secondaryMenu } = this.sideMenuConfig;
      const { name: currentRoute } = this.$route;

      const activeSecondaryMenu =
        secondaryMenu.find(menuItem =>
          menuItem.routes.includes(currentRoute)
        ) || {};
      return activeSecondaryMenu;
    },
    activePrimaryMenu() {
      const activePrimaryMenu =
        this.primaryMenuItems.find(
          menuItem => menuItem.key === this.activeSecondaryMenu.parentNav
        ) || {};
      return activePrimaryMenu;
    },
  },

  watch: {
    activeCustomView() {
      this.fetchCustomViews();
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('notifications/unReadCount');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('attributes/get');
    this.fetchCustomViews();
  },
  methods: {
    transform(id) {
      const menu = document.getElementsByClassName('el-submenu is-opened');
      if (menu.length >= 1 && menu[0].id !== `${id}`) {
        menu[0].childNodes[0].click();
      }
      const arrow_icon = document.getElementById(`arrow-icon-${id}`);
      if (
        arrow_icon.classList.value === 'initial svg-inline--fa fa-play rotate'
      ) {
        arrow_icon.classList.value = 'initial svg-inline--fa fa-play';
      } else {
        arrow_icon.classList.value = 'initial svg-inline--fa fa-play rotate';
      }
    },
    grayOut(item) {
      if (
        this.$t(`SIDEBAR.${item.label}`) === 'Campaigns' ||
        this.$t(`SIDEBAR.${item.label}`) === 'Help Center (Beta)'
      ) {
        return 'gray-out';
      }
      return '';
    },
    grayOutSubMenu(subMenu) {
      if (subMenu.label === 'APPLICATIONS') {
        return 'gray-out';
      }
      return '';
    },
    computedInboxClass(child) {
      const { channel_type, phoneNumber } = child;
      if (!channel_type) return '';
      const classByType = getInboxClassByType(channel_type, phoneNumber);
      return classByType;
    },
    fetchCustomViews() {
      if (this.isConversationOrContactActive) {
        this.$store.dispatch('customViews/get', this.activeCustomView);
      }
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
    },
    closeKeyShortcutModal() {
      this.$emit('close-key-shortcut-modal');
    },
    handleKeyEvents(e) {
      if (hasPressedCommandAndForwardSlash(e)) {
        this.toggleKeyShortcutModal();
      }
      if (isEscape(e)) {
        this.closeKeyShortcutModal();
      }

      if (hasPressedAltAndCKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('home')) {
          router.push({ name: 'home' });
        }
      } else if (hasPressedAltAndVKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('contacts_dashboard')) {
          router.push({ name: 'contacts_dashboard' });
        }
      } else if (hasPressedAltAndRKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('settings_account_reports')) {
          router.push({ name: 'settings_account_reports' });
        }
      } else if (hasPressedAltAndSKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('agent_list')) {
          router.push({ name: 'agent_list' });
        }
      }
    },
    isCurrentRouteSameAsNavigation(routeName) {
      return this.$route.name === routeName;
    },
    toggleSupportChatWindow() {
      window.$zerotouchchat.toggle();
    },
    toggleAccountModal() {
      this.$emit('toggle-account-modal');
    },
    showAddLabelPopup() {
      this.$emit('show-add-label-popup');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
    changeRoute(chlidId) {
      const childNode = document.getElementById(chlidId);
      childNode.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.woot-sidebar {
  background: var(--white);
  display: flex;
  min-height: 0;
  height: 100%;
  width: fit-content;
}
</style>

<style lang="scss">
@import '~dashboard/assets/scss/variables';

.account-selector--modal {
  .modal-container {
    width: 40rem;
  }
}
.off-canvas-content.is-open-left {
  transform: translateX(18.8rem);
}

.secondary-sidebar {
  overflow-y: auto;
  height: 100%;
}

.account-selector {
  cursor: pointer;
  padding: $space-small $space-large;

  .selected--account {
    margin-top: -$space-smaller;

    & + .account--details {
      padding-left: $space-normal - $space-micro;
    }
  }

  .account--details {
    padding-left: $space-large + $space-smaller;
  }

  &:last-child {
    margin-bottom: $space-large;
  }

  a {
    align-items: center;
    cursor: pointer;
    display: flex;

    .account--name {
      cursor: pointer;
      font-size: $font-size-medium;
      font-weight: $font-weight-medium;
      line-height: 1;
    }

    .account--role {
      cursor: pointer;
      font-size: $font-size-mini;
      text-transform: capitalize;
    }
  }
}

.sidebar-menu {
  width: 20%;
  overflow-y: auto;
  height: calc(100vh - 71px);
  height: calc(var(--vh100, 100vh) - 71px);
  background-color: white !important;
  background: padding-box;
  box-shadow: var(--shadow-context-menu);
}

@media only screen and (max-width: 667px) {
  .sidebar-menu {
    width: 80%;
  }
}

.el-menu {
  border-right: 0px !important;
}

.el-menu-vertical-demo {
  margin-top: 20px !important;
}

.el-submenu__icon-arrow.el-icon-arrow-down {
  display: none;
}

.el-menu-item-group {
  ul {
    padding-bottom: 14px !important;
  }
}

.el-menu-item {
  min-width: 175px !important;
}

.el-menu-item,
.el-submenu__title {
  line-height: 30px !important;
  height: fit-content !important;
  font-size: var(--font-size-small) !important;
  span,
  svg {
    color: black !important;
    font-size: var(--font-size-small) !important;
    line-height: 30px !important;
  }
}

.app-context-menu {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 6rem;
}

.current-user--options {
  font-size: $font-size-big;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
}

.secondary-menu .nested.vertical.menu {
  margin-left: var(--space-small);
}

.user-menu {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  // margin-bottom: var(--space-normal);
}

.rotate {
  transform: rotate(90deg);
}

.close {
  position: absolute;
  right: 32px;
  top: 5px;
  width: 0px;
  height: 32px;
  opacity: 0.3;
  z-index: 1;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 10px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.badge--label,
.badge--icon {
  display: inline-flex;
  border-radius: var(--border-radius-small);
  margin-right: var(--space-smaller);
  background: var(--s-100);
}

.badge--label {
  height: var(--space-slab);
  min-width: var(--space-slab);
  margin-left: var(--space-smaller);
  border: 1px solid var(--color-border-light);
}

.off-canvas.is-closed {
  width: 0 !important;
  visibility: visible;
}

.off-canvas.is-open {
  width: 20% !important;
}

@media only screen and (max-width: 667px) {
  .off-canvas.is-open {
    width: 80% !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 668px) {
  .off-canvas.is-open {
    width: 40% !important;
  }
}

.off-canvas {
  transition: width 0.5s ease !important;
}

.sidebar-menu-list-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.non-desktop-msg {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 1rem var(--space-medium) 1rem;
  border: var(--border-radius-normal) solid #d9dbda;
  border-radius: 15px;
  background: #eaf3ef;
  text-align: center;
  height: fit-content;
}

.warning-icon {
  color: rgb(202, 6, 6);
  margin-top: var(--space-small);
}

.non-desktop-msg p:first-of-type {
  font-weight: var(--font-weight-bold);
  margin-top: var(--space-small);
}

.non-desktop-msg p:not(:first-of-type) {
  font-size: var(--font-size-mini);
}

.non-desktop-msg p:last-of-type {
  margin-top: var(--space-small);
}
</style>
