<template>
  <woot-button variant="link" class="current-user" @click="handleClick">
    <div class="user-details">
      <div class="user-name-wrapper">
        <span class="user-name">Logged in as:</span>
        <span class="user-name">{{ currentUser.name }}</span>
      </div>
      <font-awesome-icon class="icon" icon="fa-solid fa-play" />
      <span class="user-role">{{ getRole }}</span>
    </div>
  </woot-button>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    getRole() {
      if (this.currentUser.role === 'administrator') {
        return '- Manager';
      }
      return `- ${this.currentUser.role.charAt(0).toUpperCase() +
        this.currentUser.role.slice(1)}`;
    },
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style scoped lang="scss">
.current-user {
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--white);
  margin-bottom: 5px;
  &:hover {
    background: transparent;
  }
}

.user {
  &-details {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &-name-wrapper {
    display: flex;
    gap: 5px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }

  &-name,
  &-role {
    width: fit-content;
    white-space: nowrap;
    font-size: 15px;
    margin: 0;
    color: #6e6f73;
    @media (min-width: 470px) and (max-width: 768px) {
      font-size: 12px;
    }
    @media (max-width: 470px) {
      font-size: 10px;
    }
  }
}

.icon {
  transform: rotate(90deg);
  color: #6e6f73;
  font-size: 8px;
}
</style>
