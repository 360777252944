/* eslint arrow-body-style: 0 */
import SettingsContent from '../Wrapper';
import Settings from './Settings';
import InboxHome from './Index';
import InboxChannel from './InboxChannels';
import ChannelList from './ChannelList';
import channelFactory from './channel-factory';
import AddAgents from './AddAgents';
import FinishSetup from './FinishSetup';
import { frontendURL } from '../../../../helper/URLHelper';
import store from '../../../../store';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/inboxes'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_inbox_list';
        return {
          headerTitle: 'INBOX_MGMT.HEADER',
          headerButtonText: 'SETTINGS.INBOXES.NEW_INBOX',
          icon: 'mail-inbox-all',
          newButtonRoutes: ['settings_inbox_list'],
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_inbox',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_inbox_list',
          component: InboxHome,
          roles: ['administrator'],
          meta: { title: 'Inboxes | ZeroTouch Chat' },
        },
        {
          path: 'new',
          component: InboxChannel,
          children: [
            {
              path: '',
              name: 'settings_inbox_new',
              component: ChannelList,
              meta: { title: 'Inboxes | ZeroTouch Chat' },
              roles: ['administrator'],
              beforeEnter: (to, from, next) => {
                if (to.query.redirect === 'true') {
                  next(
                    bus.$emit(
                      'newToastMessage',
                      'You are only allowed to create 1 Microsoft Teams inbox. To modify your existing Microsoft Teams inbox go to the "Inboxes" section.'
                    )
                  );
                }
                next();
              },
            },
            {
              path: ':inbox_id/finish',
              name: 'settings_inbox_finish',
              component: FinishSetup,
              meta: { title: 'Inboxes | ZeroTouch Chat' },
              roles: ['administrator'],
            },
            {
              path: ':sub_page',
              name: 'settings_inboxes_page_channel',
              component: channelFactory.create(),
              meta: { title: 'Inboxes | ZeroTouch Chat' },
              roles: ['administrator'],
              props: route => {
                return { channel_name: route.params.sub_page };
              },
              beforeEnter: async (to, from, next) => {
                if (to.params.sub_page === 'teams') {
                  await store.dispatch('inboxes/get');
                  let channelTeam =
                    store.getters['inboxes/getTeamsInboxes'].length;
                  if (channelTeam >= 1) {
                    next(
                      `/app/accounts/${to.params.accountId}/settings/inboxes/new?redirect=true`
                    );
                  }
                }
                next();
              },
            },
            {
              path: ':inbox_id/agents',
              name: 'settings_inboxes_add_agents',
              roles: ['administrator'],
              component: AddAgents,
              meta: { title: 'Inboxes | ZeroTouch Chat' },
            },
          ],
        },
        {
          path: ':inboxId',
          name: 'settings_inbox_show',
          component: Settings,
          meta: { title: 'Inboxes | ZeroTouch Chat' },
          roles: ['administrator'],
        },
      ],
    },
  ],
};
