<template>
  <div class="stats-wrapper">
    <div class="stats-body">
      <div class="card">
        <div class="stats-data">
          <h4>{{ created }}</h4>
        </div>
        <div class="card-body">
          <h5>CHATS CREATED</h5>
          <span>(Last 30 Days)</span>
        </div>
      </div>
      <div class="card">
        <div class="stats-data">
          <h4>{{ resolved }}</h4>
        </div>
        <div class="card-body">
          <h5>CHATS RESOLVED</h5>
          <span>(Last 30 Days)</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    created: {
      type: Number,
      default: 0,
    },
    resolved: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
.stats-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7rem;

  .stats-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .card {
      width: 23rem;
      padding-top: 3rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .stats-data {
        border-top: 2px solid var(--w-500);

        h4 {
          display: flex;
          justify-content: center;
          margin-top: 1rem;
          margin-bottom: 0;
        }
      }

      .card-body {
        h5 {
          font-size: 1em;
          text-align: center;
          font-weight: 700;
          margin-bottom: 0;
          color: lightgray;
        }

        span {
          text-align: center;
          color: lightgray;
          display: block;
        }
      }
    }
  }
}
</style>
