/* eslint no-console: 0 */
/* eslint-env browser */
/* eslint-disable no-new */
/* Vue Core */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import axios from 'axios';
// Global Components
import hljs from 'highlight.js';
import Multiselect from 'vue-multiselect';
import VueFormulate from '@braid/vue-formulate';
import WootSwitch from 'components/ui/Switch';
import WootWizard from 'components/ui/Wizard';
import { sync } from 'vuex-router-sync';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import WootUiKit from '../dashboard/components';
import App from '../dashboard/App';
import i18n from '../dashboard/i18n';
import createAxios from '../dashboard/helper/APIHelper';
import commonHelpers, { isJSONValid } from '../dashboard/helper/commons';
import {
  getAlertAudio,
  initOnEvents,
} from '../shared/helpers/AudioNotificationHelper';
import { initFaviconSwitcher } from '../shared/helpers/faviconHelper';
import router, { initalizeRouter } from '../dashboard/routes';
import store from '../dashboard/store';
import constants from '../dashboard/constants';
import * as Sentry from '@sentry/vue';
import 'vue-easytable/libs/theme-default/index.css';
import { Integrations } from '@sentry/tracing';
import posthog from 'posthog-js';
import {
  initializeAnalyticsEvents,
  initializeZerotouchChatEvents,
} from '../dashboard/helper/scriptHelpers';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { domPurifyConfig } from '../shared/helpers/HTMLSanitizer';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

Vue.config.env = process.env;
library.add(faHatWizard, fas);

if (window.errorLoggingConfig) {
  Sentry.init({
    Vue,
    dsn: window.errorLoggingConfig,
    integrations: [new Integrations.BrowserTracing()],
  });
}

if (window.analyticsConfig) {
  posthog.init(window.analyticsConfig.token, {
    api_host: window.analyticsConfig.host,
  });
}

Vue.use(VueDOMPurifyHTML, domPurifyConfig);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
Vue.use(VueFormulate, {
  rules: {
    JSON: ({ value }) => isJSONValid(value),
  },
});
Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(hljs.vuePlugin);
Vue.use(Element);

Vue.component('multiselect', Multiselect);
Vue.component('woot-switch', WootSwitch);
Vue.component('woot-wizard', WootWizard);
Vue.component('fluent-icon', FluentIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const i18nConfig = new VueI18n({
  locale: 'en',
  messages: i18n,
});

sync(store, router);
// load common helpers into js
commonHelpers();

window.WootConstants = constants;
window.axios = createAxios(axios);
window.bus = new Vue();
initializeZerotouchChatEvents();
initializeAnalyticsEvents();
initalizeRouter();

const isIphone = window.CSS?.supports?.(
  '(font:-apple-system-body) and (-webkit-touch-callout:none) and (-webkit-tap-highlight-color:hotpink)'
);

window.onload = () => {
  if (isIphone) {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport !== null) {
      viewport.content = `${viewport.content},user-scalable=no`;
    }
  }
  window.WOOT = new Vue({
    router,
    store,
    i18n: i18nConfig,
    components: { App },
    template: '<App/>',
  }).$mount('#app');
};

const setupAudioListeners = () => {
  getAlertAudio().then(() => {
    initOnEvents.forEach(event => {
      document.removeEventListener(event, setupAudioListeners, false);
    });
  });
};

const setViewPortHeight = () => {
  let vh100 = window.innerHeight;
  document.documentElement.style.setProperty('--vh100', `${vh100}px`);
};

window.addEventListener('load', () => {
  window.playAudioAlert = () => {};
  initOnEvents.forEach(e => {
    document.addEventListener(e, setupAudioListeners, false);
  });
  initFaviconSwitcher();
  setViewPortHeight();
});

window.addEventListener('resize', () => {
  setViewPortHeight();
});
