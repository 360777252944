<template>
  <div class="page-wrapper">
    <img src="~widget/assets/images/teamserror.png" alt="it-help" />
    <div class="title">Something went wrong.</div>
    <div class="description">
      <span v-html="errorMessage" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  img {
    width: 30%;
  }
  .title {
    font-weight: 600;
    font-size: 1.5em;
  }
  .description {
    text-align: center;
  }
}
</style>
