<template>
  <div
    v-if="!authUIFlags.isFetching && !authFailed"
    id="app"
    class="app-wrapper app-root"
  >
    <component :is="renderComponent" />
    <update-banner
      :latest-zerotouch-chat-version="latestZerotouchChatVersion"
    />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
  </div>
  <div v-else-if="authFailed" class="dashboard-error">
    <error-page :error-message="errorMessage" />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification';
import UpdateBanner from './components/app/UpdateBanner.vue';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer';
import * as microsoftTeams from '@microsoft/teams-js';
import Cookies from 'js-cookie';
import ErrorPage from 'shared/components/ErrorPage';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';
export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    UpdateBanner,
    WootSnackbarBox,
    ErrorPage,
  },

  data() {
    return {
      showAddAccountModal: false,
      latestZerotouchChatVersion: null,
      microsoftTeams,
      authFailed: false,
      user: {},
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      authUIFlags: 'getAuthUIFlags',
      currentAccountId: 'getCurrentAccountId',
      isOnTeamWindow: 'layout/isOnTeamWindow',
    }),
    renderComponent() {
      if (this.isOnTeamWindow) {
        return () => import(`./AppTeams`);
      }
      return '';
    },
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
    errorMessage() {
      return `It looks like your account, ${
        this.user.email ? `${this.user.email},` : ''
      } doesn't have <br />access to this application. Please contact your IT to get access.`;
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.setIsOnDesktop();
    this.setIsOnTeamWindow();
    window.addEventListener('resize', this.setIsOnDesktop);
    this.setLocale(window.zerotouchChatConfig.selectedLocale);
    if (this.isOnTeamWindow) {
      this.intializeTeamsApp();
      if (!Cookies.getJSON('cw_d_session_info')) {
        this.getAuth();
      }
    }
  },
  methods: {
    setIsOnTeamWindow() {
      if (
        navigator.userAgent.indexOf('Teams') !== -1 ||
        window.name === 'embedded-page-container'
      ) {
        this.$store.dispatch('layout/setIsOnTeamWindow', true);
      } else {
        this.$store.dispatch('layout/setIsOnTeamWindow', false);
      }
    },
    setIsOnDesktop() {
      if (window.innerWidth > 1200) {
        this.$store.dispatch('layout/setIsOnDesktop', true);
      } else {
        this.$store.dispatch('layout/setIsOnDesktop', false);
      }
    },
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });
      const {
        locale,
        latest_zerotouch_chat_version: latestZerotouchChatVersion,
      } = this.getAccount(this.currentAccountId);
      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.latestZerotouchChatVersion = latestZerotouchChatVersion;
      vueActionCable.init(pubsubToken);
    },
    async getAuth() {
      try {
        const token = await this.microsoftTeams.authentication.getAuthToken({
          resources: ['https://graph.microsoft.com/v1.0'],
        });
        await this.$store.dispatch('microsoftLogin', token);
      } catch (error) {
        this.authFailed = true;
        if (error?.status === 401) {
          this.user = error.data;
        }
      }
    },
    async intializeTeamsApp() {
      await this.microsoftTeams.initialize();
      await this.microsoftTeams.app.initialize();
      this.microsoftTeams.app.notifySuccess();
    },
  },
};
</script>
<style lang="scss">
@import './assets/scss/app';
.update-banner {
  height: var(--space-larger);
  align-items: center;
  font-size: var(--font-size-small) !important;
}

.dashboard-error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
