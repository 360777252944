import ClientsView from './components/ClientsView';
import ClientManageView from './pages/ClientManageView';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/clients'),
    name: 'clients_dashboard',
    roles: ['administrator'],
    component: ClientsView,
    meta: { title: 'Client | ZeroTouch Chat' },
  },
  {
    path: frontendURL('accounts/:accountId/clients/:clientId'),
    name: 'client_profile_dashboard',
    roles: ['administrator'],
    component: ClientManageView,
    meta: { title: 'Client | ZeroTouch Chat' },
    props: route => {
      return { clientId: route.params.clientId };
    },
  },
];
