<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <woot-button @click="changeStatus()">{{ buttonText }}</woot-button>
    </div>
  </transition>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from '../../../../../shared/mixins/alertMixin';

export default {
  mixins: [clickaway, alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    buttonText() {
      return this.user.status === 'enabled' ? 'Disable' : 'Enable';
    },
  },
  methods: {
    onClickAway() {
      if (this.show) this.close();
    },
    async changeStatus() {
      try {
        let status = this.user.status === 'enabled' ? 'disable' : 'enable';
        let notify_status =
          this.user.status === 'enabled' ? 'disabled' : 'enabled';
        this.$store.dispatch('clients/updateClientUserStatus', {
          id: this.user.id,
          status: status,
        });
        this.showAlert(`User has been ${notify_status}!`);
      } catch (error) {
        this.showAlert(error.message || 'Server Error');
      }
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  left: 1rem;
  z-index: var(--z-index-very-high);
  padding: 0.5%;
  padding-bottom: 0%;
  border-radius: 0.5rem;
  border: 1px solid #8080805e;
  .button {
    background-color: white;
    color: black;
    min-width: 12rem;
    padding-left: 1rem;
    text-align: left;
  }
  .button:hover {
    background-color: var(--s-50);
  }
}
</style>
