import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  companies: [],
  boards: [],
  contacts: [],
  sites: [],
  types: [],
  statuses: [],
  uiFlags: {
    isFetchingCompanies: false,
    isFetchingBoards: false,
    isFetchingContacts: false,
    isFetchingSites: false,
    isFetchingTypes: false,
    isFetchingStatuses: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
