var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-modal',{attrs:{"show":_vm.show,"on-close":_vm.onClose},on:{"update:show":function($event){_vm.show=$event}}},[_c('div',{staticClass:"add-client-wrapper"},[_c('woot-modal-header',{attrs:{"header-title":'Add Client'}}),_vm._v(" "),_c('div',{staticClass:"row modal-body add-client-wrapper"},[_c('div',{staticClass:"columns medium-12 options-wrapper"},[_c('span',{staticClass:"label"},[_vm._v("Client List")]),_vm._v(" "),_c('div',[_c('multiselect',{attrs:{"options":_vm.options(),"track-by":"_id","label":"client_name","multiple":false,"close-on-select":true,"clear-on-select":false,"hide-selected":true,"max-height":175,"selected-label":"","select-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_SELECT'),"deselect-label":_vm.$t('FORMS.MULTISELECT.ENTER_TO_REMOVE'),"placeholder":"Select Client","open-direction":"bottom","loading":_vm.uiFlags.isFetchingNewClients},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.addNewClient)?_c('span',[(_vm.currentUser.is_msp_admin)?_c('a',{staticClass:"add-new-client-link",attrs:{"target":"blank","href":props.option.addNewClient}},[_vm._v("\n                  Add New Client\n                ")]):_vm._e(),_vm._v("\n                "+_vm._s(!_vm.currentUser.is_msp_admin ? 'Add New Client' : null)+"\n                "),(!_vm.currentUser.is_msp_admin)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:({
                    boundariesElement: 'body',
                    content:
                      'Your not an Admin, you cannot create a new client. Please contact your Admin to create a new client',
                  }),expression:"{\n                    boundariesElement: 'body',\n                    content:\n                      'Your not an Admin, you cannot create a new client. Please contact your Admin to create a new client',\n                  }",modifiers:{"top-end":true}}]},[_c('fluent-icon',{attrs:{"icon":"info"}})],1):_vm._e()]):(props.option.$isDisabled)?_c('span',{staticClass:"info"},[_vm._v("\n                "+_vm._s(props.option.client_name)+"\n                "),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:({
                    boundariesElement: 'body ',
                    content:
                      'Cannot select this client. This client has already been added to the ZeroTouch Chat page.',
                  }),expression:"{\n                    boundariesElement: 'body ',\n                    content:\n                      'Cannot select this client. This client has already been added to the ZeroTouch Chat page.',\n                  }",modifiers:{"top-end":true}}]},[_c('fluent-icon',{attrs:{"icon":"info"}})],1)]):_c('span',[_vm._v(_vm._s(props.option.client_name))])]}}]),model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('woot-button',{attrs:{"variant":"smooth","color-scheme":"dark"},on:{"click":function($event){$event.preventDefault();return _vm.onClose($event)}}},[_vm._v("\n        Cancel\n      ")]),_vm._v(" "),_c('woot-button',{attrs:{"is-disabled":_vm.isDisabled(),"is-loading":_vm.uiFlags.isAdding},on:{"click":_vm.addClient}},[_vm._v("\n        Add Client\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }