<template>
  <div class="create-resolve-ticket-wrapper">
    <woot-modal-header
      :header-title="'Create a New Ticket'"
      class="merge-class-header"
    />
    <div class="modal-body">
      <div class="row row-media-query">
        <div class="medium-7 columns margin-class-create-ticket input-margin">
          <woot-input
            v-model="summary"
            :label="$t('CONVERSATION.CREATE_RESOLVE_TICKET.SUMMARY')"
            class="font-class"
          />
        </div>
      </div>
      <div class="row row-media-query">
        <div class="medium-4 columns margin-class-create-ticket input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.COMPANY') }}
          </label>
          <multiselect
            v-model="selectedCompany"
            :options="companies"
            track-by="id"
            label="name"
            :multiple="false"
            :loading="uiFlags.isFetchingCompanies"
            placeholder="Select Company"
            :show-labels="false"
            open-direction="bottom"
            :max-height="175"
            @input="onCompanyInput"
          />
        </div>
        <div class="medium-2 columns" />
        <div class="medium-4 columns input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.CONTACT') }}
          </label>
          <multiselect
            v-model="selectedContact"
            :options="contacts"
            track-by="id"
            label="fullName"
            :multiple="false"
            :loading="uiFlags.isFetchingContacts"
            placeholder="Select Contact"
            open-direction="bottom"
            :show-labels="false"
            :max-height="175"
          />
        </div>
      </div>
      <div class="row row-media-query">
        <div class="medium-4 columns margin-class-create-ticket input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.SITE') }}
          </label>
          <multiselect
            v-model="selectedSite"
            :options="sites"
            track-by="id"
            label="name"
            :multiple="false"
            :loading="uiFlags.isFetchingSites"
            placeholder="Select Site"
            open-direction="top"
            :show-labels="false"
            :max-height="175"
          />
        </div>
        <div class="medium-2 columns" />
        <div class="medium-4 columns input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.TYPE') }}
          </label>
          <multiselect
            v-model="selectedType"
            :options="types"
            track-by="id"
            label="name"
            :multiple="false"
            placeholder="Select Type"
            :show-labels="false"
            :loading="uiFlags.isFetchingTypes"
            open-direction="top"
            :max-height="175"
          />
        </div>
      </div>
      <div class="row row-media-query">
        <div class="medium-4 columns margin-class-create-ticket input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.BOARD') }}
          </label>
          <multiselect
            v-model="selectedBoard"
            :options="boards"
            track-by="id"
            label="name"
            :multiple="false"
            :loading="uiFlags.isFetchingBoards"
            placeholder="Select Board"
            :show-labels="false"
            open-direction="top"
            :max-height="175"
            @input="getTypesAndStatus()"
          />
        </div>
        <div class="medium-2 columns" />
        <div class="medium-4 columns input-margin">
          <label class="font-class">
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.STATUS') }}
          </label>
          <multiselect
            v-model="selectedStatus"
            :options="statuses"
            track-by="id"
            label="name"
            :multiple="false"
            placeholder="Select Status"
            :show-labels="false"
            :loading="uiFlags.isFetchingStatuses"
            :max-height="175"
            open-direction="top"
          />
        </div>
      </div>
      <div class="row margin-class-create-ticket footer">
        <woot-button
          :is-disabled="disableCreateButton"
          :is-loading="isSubmitting"
          @click="createTicket()"
        >
          Submit
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('AUTOMATION.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Connectwise from '../../api/connectwise';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../constants';
import { CMD_RESOLVE_CONVERSATION } from '../../routes/dashboard/commands/commandBarBusEvents';

export default {
  mixins: [alertMixin],
  data() {
    return {
      disableCreateButton: true,
      summary: '',
      selectedType: [],
      selectedCompany: [],
      selectedBoard: [],
      selectedContact: [],
      selectedStatus: [],
      selectedSite: [],
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      companies: 'connectwise/getCompanies',
      uiFlags: 'connectwise/getUiFlags',
      boards: 'connectwise/getBoards',
      contacts: 'connectwise/getContacts',
      currentChat: 'getSelectedChat',
      accountDetails: 'getCurrentAccount',
      sites: 'connectwise/getSites',
      types: 'connectwise/getTypes',
      statuses: 'connectwise/getStatuses',
    }),
  },
  watch: {
    summary() {
      this.checkLength(this.summary);
    },
    selectedSite() {
      this.checkLength(this.selectedSite);
    },
    selectedCompany() {
      this.checkLength(this.selectedCompany);
    },
    selectedBoard() {
      this.checkLength(this.selectedBoard);
    },
    selectedStatus() {
      this.checkLength(this.selectedStatus);
    },
    selectedContact() {
      this.checkLength(this.selectedContact);
    },
  },
  mounted() {
    this.$store.dispatch('connectwise/companies');
    this.$store.dispatch('connectwise/boards');
    bus.$on(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);
  },
  destroyed() {
    bus.$off(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);
  },
  methods: {
    checkLength(params) {
      if (
        params !== null &&
        Object.keys(params).length > 0 &&
        this.summary.length > 0
      ) {
        if (
          Object.keys(this.selectedContact).length &&
          Object.keys(this.selectedBoard).length &&
          Object.keys(this.selectedCompany).length &&
          Object.keys(this.selectedStatus).length &&
          Object.keys(this.selectedSite).length
        ) {
          this.disableCreateButton = false;
        }
      } else {
        this.disableCreateButton = true;
      }
    },
    onClose() {
      this.$emit('close');
    },
    onCompanyInput() {
      this.getSites();
      this.getContacts();
    },
    async getSites() {
      this.selectedSite = [];
      if (this.selectedCompany?.id) {
        try {
          await this.$store.dispatch(
            'connectwise/sites',
            this.selectedCompany.id
          );
        } catch (error) {
          this.showAlert(error);
        }
      }
    },
    async getContacts() {
      this.selectedContact = [];
      if (this.selectedCompany?.id) {
        try {
          await this.$store.dispatch(
            'connectwise/contacts',
            this.selectedCompany.id
          );
        } catch (error) {
          this.showAlert(error);
        }
      }
    },
    async getTypesAndStatus() {
      this.selectedStatus = [];
      this.selectedType = [];
      this.$store
        .dispatch('connectwise/types', this.selectedBoard.id)
        .catch(error => {
          this.showAlert(error);
        });
      this.$store
        .dispatch('connectwise/statuses', this.selectedBoard.id)
        .catch(error => {
          this.showAlert(error);
        });
    },
    async createTicket() {
      try {
        const postData = {
          company: {
            id: this.selectedCompany.id,
            name: this.selectedCompany.name,
          },
          summary: this.summary,
          contact: {
            id: this.selectedContact.id,
            name: this.selectedContact.name,
          },
          site: {
            id: this.selectedSite.id,
            name: this.selectedSite.name,
          },
          type: {
            id: this.selectedType.id,
            name: this.selectedType.name,
          },
          board: {
            id: this.selectedBoard.id,
            name: this.selectedBoard.name,
          },
          status: {
            id: this.selectedStatus.id,
            name: this.selectedStatus.name,
          },
        };
        this.isSubmitting = true;
        const response = await Connectwise.createTicket(
          postData,
          this.currentChat.id
        );
        this.isSubmitting = false;
        if (response.data.data.errors) {
          this.showAlert(response.data.data.errors[0].message);
        } else {
          this.$emit('close');
          this.showAlert('Service ticket has been successfully created');
          this.onCmdResolveConversation();
        }
      } catch (error) {
        this.showAlert(error.response.data.message || 'server error');
      }
    },
    onCmdResolveConversation() {
      this.toggleStatus(this.STATUS_TYPE.RESOLVED);
    },
    toggleStatus(status, snoozedUntil) {
      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status,
          snoozedUntil,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
        });
    },
  },
};
</script>
