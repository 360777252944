<template>
  <transition name="menu-slide">
    <div
      v-if="show"
      v-on-clickaway="onClickAway"
      class="dropdown-pane"
      :class="{ 'dropdown-pane--open': show }"
    >
      <div class="menu-title-wrapper">
        {{ account.name }}
        <woot-button
          v-if="isOnTeamWindow"
          color-scheme="no-color"
          icon="arrow-clockwise"
          @click="refresh()"
        />
      </div>
      <br />
      <div class="menu">
        <woot-dropdown-menu class="profile-menu menu-components">
          <div class="logo-status-title">
            <thumbnail
              :src="currentUser.avatar_url"
              :username="currentUser.name"
              size="68px"
              class="logo"
            />
            <strong class="chat-title">
              {{ $t('SIDEBAR.SET_AVAILABILITY_TITLE') + ':' }}
            </strong>
          </div>
          <div class="user-details">
            <p>
              <strong>{{ currentUser.name }}</strong>
            </p>
            <p>
              <strong class="user-email">{{ currentUser.email }}</strong>
            </p>
            <woot-dropdown-item>
              <a class="content" :href="`${mspUrl}/personal`" target="_blank">
                {{ 'View ' + $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
              </a>
            </woot-dropdown-item>
            <woot-dropdown-item>
              <a
                v-if="currentUser.is_msp_admin"
                class="button__content  content"
                :href="`${mspUrl}/admincenter`"
                target="_blank"
              >
                {{ 'View Admin Center' }}
              </a>
            </woot-dropdown-item>
            <woot-dropdown-item v-if="globalConfig.zerotouchChatInboxToken">
              <span
                variant="clear"
                color-scheme="secondary"
                size="small"
                @click="$emit('show-support-chat-window')"
              >
                <span class="content">
                  {{ $t('SIDEBAR_ITEMS.CONTACT_SUPPORT') }}
                </span>
              </span>
            </woot-dropdown-item>
            <woot-dropdown-item v-if="isOnDesktop">
              <span
                class="dropdown-link"
                variant="clear"
                color-scheme="secondary"
                size="small"
                @click="handleKeyboardHelpClick"
              >
                <span class="content">
                  {{ 'View ' + $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
                </span>
              </span>
            </woot-dropdown-item>
            <woot-dropdown-item v-if="isOnDesktop">
              <router-link
                v-slot="{ href, isActive, navigate }"
                class="dropdown-link"
                :to="`/app/accounts/${accountId}/profile/settings`"
                custom
              >
                <a
                  :href="href"
                  :class="{ 'is-active': isActive }"
                  @click="e => handleProfileSettingClick(e, navigate)"
                >
                  <span class="content">
                    {{ 'View Chat Settings' }}
                  </span>
                </a>
              </router-link>
            </woot-dropdown-item>
          </div>
        </woot-dropdown-menu>
      </div>
      <span class="divider" />
      <availability-status />
      <woot-button
        v-if="!isOnTeamWindow"
        class="logout"
        variant="clear"
        color-scheme="secondary"
        size="small"
        @click="logout"
      >
        <strong>{{ $t('SIDEBAR_ITEMS.LOGOUT') }}</strong>
      </woot-button>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import AvailabilityStatus from 'dashboard/components/layout/AvailabilityStatus';
import Thumbnail from '../../widgets/Thumbnail';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatus,
    Thumbnail,
  },
  mixins: [clickaway],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
      isOnDesktop: 'layout/isOnDesktop',
      isOnTeamWindow: 'layout/isOnTeamWindow',
      account: 'getCurrentAccount',
    }),
    mspUrl() {
      return `${window.zerotouchChatConfig.mspUrl}`;
    },
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
  },
  methods: {
    handleProfileSettingClick(e, navigate) {
      this.$emit('close');
      navigate(e);
    },
    handleKeyboardHelpClick() {
      this.$emit('key-shortcut-modal');
      this.$emit('close');
    },
    logout() {
      Auth.logout();
    },
    onClickAway() {
      if (this.show) this.$emit('close');
    },
    refresh() {
      window.location.replace(window.location.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  right: var(--space-slab);
  top: var(--space-larger);
  min-width: 16.8rem;
  z-index: var(--z-index-very-high);
  padding: 0.5%;
  padding-bottom: 0%;
  padding-right: 0%;
  margin-left: 10px;
  @media only screen and (max-width: 767px) {
    max-width: 90vw;
  }
}
.profile-menu {
  display: flex;
  flex-direction: row !important;
  column-gap: 1em;
  height: auto;
  margin-bottom: 10px;
}
.logo {
  margin-left: 20px;
}
.dropdown-link {
  padding: 0%;
  margin: 0%;
  color: #1f93ff;
}
.profile-menu .dropdown-menu__item a:hover,
.dropdown-menu__item .button:hover {
  background-color: transparent;
}
.dropdown-menu__item a,
.dropdown-menu__item .button {
  display: inline-flex;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  color: #1f93ff;
}
.content {
  color: #1f93ff;
  text-decoration: underline;
  font-size: 1.3rem;
  cursor: pointer;
  padding: 0;
}
.logout {
  display: inline-flex;
  float: right;
  padding: 5px;
  border-top: 0.5px solid rgb(232, 228, 228) !important;
  border-left: 0.5px solid rgb(232, 228, 228) !important;
}
p {
  margin: 0;
}
.logo-status-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat-title {
  font-size: 1.2rem;
}
.no-color {
  background-color: unset;
  color: black;
}

.no-color:hover {
  background-color: unset;
  color: black;
}
.menu-title-wrapper {
  display: flex;
  justify-content: space-between;
  .button {
    height: auto;
    &:hover {
      color: var(--w-500);
    }
  }
}

.user-details {
  margin-right: 10px;
  overflow-wrap: anywhere;
}

.user-details {
  @media screen and (max-width: 500px) {
    max-width: 260px;
  }
}
</style>
