var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"woot-sidebar"},[_c('div',{staticClass:"sidebar-menu",class:_vm.sidebarClassName},[_c('span',{staticClass:"close",on:{"click":_vm.toggleSidebar}}),_vm._v(" "),_c('el-col',{staticClass:"sidebar-menu-list-wrapper",attrs:{"span":24}},[_c('el-menu',{staticClass:"el-menu-vertical-demo",on:{"open":_vm.transform,"close":_vm.transform}},_vm._l((_vm.primaryMenuItems),function(primaryMenu,primaryIdx){return _c('el-submenu',{key:primaryIdx,class:_vm.grayOut(primaryMenu),attrs:{"id":("" + primaryIdx),"index":primaryIdx.toString()}},[(_vm.isOnDesktop || primaryMenu.key === 'conversations')?_c('template',{slot:"title"},[_c('font-awesome-icon',{staticClass:"initial",attrs:{"id":("arrow-icon-" + primaryIdx),"icon":"fa-solid fa-play"}}),_vm._v(" "),_c('fluent-icon',{attrs:{"icon":primaryMenu.icon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(("SIDEBAR." + (primaryMenu.label)))))])],1):_vm._e(),_vm._v(" "),_vm._l((_vm.secondaryMenuItems),function(secondaryMenu,secondaryIdx){return [(primaryMenu.key == secondaryMenu.parentNav)?_c('el-menu-item-group',{key:secondaryIdx},_vm._l((secondaryMenu.menuItems),function(menuItem,menuIdx){return _c('el-menu-item',{key:menuIdx,class:_vm.grayOutSubMenu(menuItem),attrs:{"index":menuIdx.toString()},on:{"click":function($event){return _vm.changeRoute(("link-" + secondaryIdx + "-" + menuIdx))}}},[(
                    menuItem.label != 'AGENT_BOTS' &&
                      menuItem.label != 'BILLING'
                  )?_c('router-link',{attrs:{"id":("link-" + secondaryIdx + "-" + menuIdx),"to":menuItem.toState},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('fluent-icon',{attrs:{"icon":menuItem.icon}}),_vm._v(" "),_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t(("SIDEBAR." + (menuItem.label))))+"\n                  ")])],1):_vm._e()],1)}),1):_vm._e()]}),_vm._v(" "),(primaryMenu.key === 'conversations')?_c('el-menu-item-group',{attrs:{"title":"Teams"}},[_vm._l((_vm.teams),function(team,teamIdx){return _c('el-menu-item',{key:team.id,on:{"click":function($event){return _vm.changeRoute(("teamlink-" + teamIdx))}}},[_c('router-link',{attrs:{"id":("teamlink-" + teamIdx),"to":("/app/accounts/" + _vm.accountId + "/team/" + (team.id))},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('span',[_vm._v(_vm._s(team.name))])])],1)}),_vm._v(" "),(
                primaryMenu.key === 'conversations' && _vm.currentRole !== 'agent'
              )?_c('el-menu-item',{on:{"click":function($event){return _vm.changeRoute("teamlink")}}},[(_vm.isOnDesktop)?_c('router-link',{attrs:{"id":"teamlink","to":("/app/accounts/" + _vm.accountId + "/settings/teams/new")},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('span',[_vm._v("+ New team")])]):_vm._e()],1):_vm._e()],2):_vm._e(),_vm._v(" "),(primaryMenu.key === 'conversations')?_c('el-menu-item-group',{attrs:{"title":"Inboxes"}},[_vm._l((_vm.inboxes),function(inbox,inboxIdx){return _c('el-menu-item',{key:inbox.id,on:{"click":function($event){return _vm.changeRoute(("inboxlink-" + inboxIdx))}}},[_c('router-link',{attrs:{"id":("inboxlink-" + inboxIdx),"to":("/app/accounts/" + _vm.accountId + "/inbox/" + (inbox.id))},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('fluent-icon',{class:_vm.computedInboxClass(inbox),attrs:{"icon":_vm.computedInboxClass(inbox),"size":"12"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(inbox.name))])],1)],1)}),_vm._v(" "),(
                primaryMenu.key === 'conversations' && _vm.currentRole !== 'agent'
              )?_c('el-menu-item',{on:{"click":function($event){return _vm.changeRoute("inboxlink")}}},[(_vm.isOnDesktop)?_c('router-link',{attrs:{"id":"inboxlink","to":("/app/accounts/" + _vm.accountId + "/settings/inboxes/new")},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('span',[_vm._v("+ New inbox")])]):_vm._e()],1):_vm._e()],2):_vm._e(),_vm._v(" "),(
              primaryMenu.key === 'conversations' ||
                primaryMenu.key === 'contacts'
            )?_c('el-menu-item-group',{attrs:{"title":"Labels"}},[_vm._l((_vm.labels),function(label,labelIdx){return _c('el-menu-item',{key:label.title,on:{"click":function($event){return _vm.changeRoute(("labellink-" + labelIdx))}}},[_c('router-link',{attrs:{"id":("labellink-" + labelIdx),"to":primaryMenu.key === 'conversations'
                    ? ("/app/accounts/" + _vm.accountId + "/label/" + (label.title))
                    : ("/app/accounts/" + _vm.accountId + "/labels/" + (label.title) + "/contacts")},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('span',{staticClass:"badge--label",style:({ backgroundColor: label.color })}),_vm._v(" "),_c('span',[_vm._v(_vm._s(label.title))])])],1)}),_vm._v(" "),(
                (primaryMenu.key === 'conversations' ||
                  primaryMenu.key === 'contacts') &&
                  _vm.currentRole !== 'agent'
              )?_c('el-menu-item',{on:{"click":function($event){return _vm.changeRoute("labellink")}}},[(_vm.isOnDesktop)?_c('router-link',{attrs:{"id":"labellink","to":("/app/accounts/" + _vm.accountId + "/settings/labels")},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar($event)}}},[_c('span',[_vm._v("+ New label")])]):_vm._e()],1):_vm._e()],2):_vm._e()],2)}),1),_vm._v(" "),(!_vm.isOnDesktop)?_c('div',{staticClass:"non-desktop-msg",attrs:{"hidden":""}},[_c('fluent-icon',{staticClass:"warning-icon",attrs:{"icon":"warning"}}),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('SIDEBAR.NON_DESKTOP.DISPLAY_TEXT'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('SIDEBAR.NON_DESKTOP.LIMITED_MESSAGE')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('SIDEBAR.NON_DESKTOP.FUNCTIONALITY_MESSAGE')))])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }