import types from '../../mutation-types';

export const mutations = {
  [types.SET_CONNECTWISE_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_COMPANIES]($state, data) {
    $state.companies = data;
  },
  [types.SET_BOARDS]($state, data) {
    $state.boards = data;
  },
  [types.SET_CONTACTS]($state, data) {
    $state.contacts = data;
  },
  [types.SET_SITES]($state, data) {
    $state.sites = data;
  },
  [types.SET_TYPES]($state, data) {
    $state.types = data;
  },
  [types.SET_STATUSES]($state, data) {
    $state.statuses = data;
  },
};
