import { API, setHeader } from './../../../msp/helpers/axios';
import store from './../index';
const state = {
  chatPsaStatus: false,
};

const getters = {
  chatPsaStatus($state) {
    return $state.chatPsaStatus;
  },
};

const mutations = {
  ['SET_IS_ON_PSA_STATUS']: ($state, chatPsaStatus) => {
    $state.chatPsaStatus = chatPsaStatus;
  },
};

const actions = {
  setchatPsaStatus({ commit }, chatPsaStatus) {
    commit('SET_IS_ON_PSA_STATUS', chatPsaStatus);
  },
  async getChatPsaStatus({ commit }, mspClientId) {
    if (!mspClientId) {
      commit('SET_IS_ON_PSA_STATUS', false);
      return;
    }
    setHeader(store.getters.getCurrentUser.access_token);
    try {
      const response = await API.post('clientlist', { clientId: mspClientId });
      const psaConnected = response.data.data[0].data[0].psa_connected;
      commit('SET_IS_ON_PSA_STATUS', psaConnected);
    } catch (error) {
      commit('SET_IS_ON_PSA_STATUS', false);
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
