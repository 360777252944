<template>
  <div class="report-bar">
    <woot-loading-state
      v-if="uiFlags.isFetchingConversationReport"
      :message="'Chart Loading'"
    />
    <empty-state
      v-else-if="
        !uiFlags.isFetchingConversationReport && !conversationReports.length
      "
      :title="'No Reports'"
    />
    <div v-else class="chart-container">
      <woot-bar
        v-if="conversationReports.length"
        :collection="collection"
        :chart-options="chartOptions"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';
import { METRIC_CHART } from './../../settings/reports/constants';
import reportMixin from './../../../../mixins/reportMixin';
import { formatTime } from '@chatwoot/utils';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
export default {
  components: {
    EmptyState,
  },
  mixins: [reportMixin],
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'clients/getUIFlags',
      conversationReports: 'clients/getConversationReports',
    }),
    collection() {
      if (this.uiFlags.isFetchingConversationReport) {
        return {};
      }
      if (!this.conversationReports.length) return {};
      const labels = this.conversationReports.map(element => {
        return format(fromUnixTime(element.timestamp), 'dd-MMM-yyyy');
      });

      const datasets = METRIC_CHART.conversations_count.datasets.map(
        dataset => {
          switch (dataset.type) {
            case 'bar':
              return {
                ...dataset,
                yAxisID: 'y-left',
                label: 'Conversations',
                data: this.conversationReports.map(element => element.value),
              };
            default:
              return dataset;
          }
        }
      );

      return {
        labels,
        datasets,
      };
    },
    chartOptions() {
      let tooltips = {};
      if (this.isAverageMetricType('conversations_count')) {
        tooltips.callbacks = {
          label: tooltipItem => {
            return this.$t('REPORT.METRICS.CONVERSATIONS.TOOLTIP_TEXT', {
              metricValue: formatTime(tooltipItem.yLabel),
              conversationCount: this.conversationReports[tooltipItem.index]
                .count,
            });
          },
        };
      }

      return {
        scales: METRIC_CHART.conversations_count.scales,
        tooltips: tooltips,
      };
    },
  },
  mounted() {
    this.$store.dispatch('clients/getConversationReports', {
      id: this.clientId,
      metric: 'conversations_count',
      type: 'client',
      from: getUnixTime(startOfDay(subDays(new Date(), 29))),
      to: getUnixTime(endOfDay(new Date())),
      businessHours: false,
      groupBy: 'day',
    });
  },
};
</script>
<style lang="scss" scoped>
.report-bar {
  margin-bottom: 7rem;
}
</style>
