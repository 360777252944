/* global axios */
import ApiClient from './ApiClient';

class MicrosoftTeamsDeploy extends ApiClient {
  constructor() {
    super('microsoft_teams_deploy', { accountScoped: true });
  }

  deployZeroTouchChatApp(accountId) {
    return axios.post(`${this.url}/deploy_zerotouch_chat_app_to_teams`, {
      account_id: accountId,
    });
  }
}

export default new MicrosoftTeamsDeploy();
