<template>
  <div>
    <div class="app-wrapper">
      <div class="user-menu">
        <div style="width: 92vw;">
          <router-link :to="`/app/accounts/${accountId}/dashboard`">
            <img
              src="../../../../../public/brand-assets/logo.png"
              class="zero-touch-logo"
              width="150"
              height="150"
            />
          </router-link>
        </div>
        <div class="account">
          <a
            class="zero-touch-name"
            href="https://zerotouchmsp.com/dashboard"
            target="_blank"
          >
            <span>ZeroTouchMSP</span>
            <span>Dashboard</span>
          </a>
          <notification-bell
            v-if="!isOnTeamWindow"
            @open-notification-panel="openNotificationPanel"
          />
          <agent-details @toggle-menu="toggleOptions" />
          <options-menu
            :show="showOptionsMenu"
            @toggle-accounts="toggleAccountModal"
            @show-support-chat-window="toggleSupportChatWindow"
            @key-shortcut-modal="toggleKeyShortcutModal"
            @close="toggleOptions"
          />
        </div>
      </div>
      <div class="row app-body">
        <sidebar
          v-on-clickaway="closeSidebar"
          :route="currentRoute"
          :sidebar-class-name="sidebarClassName"
          @open-notification-panel="openNotificationPanel"
          @toggle-sidebar="toggleSidebar"
          @toggle-account-modal="toggleAccountModal"
          @open-key-shortcut-modal="toggleKeyShortcutModal"
          @close-key-shortcut-modal="closeKeyShortcutModal"
          @show-add-label-popup="showAddLabelPopup"
        />
        <section class="app-content columns" :class="contentClassName">
          <router-view />
          <command-bar />
          <account-selector
            :show-account-modal="showAccountModal"
            @close-account-modal="toggleAccountModal"
            @show-create-account-modal="openCreateAccountModal"
          />
          <add-account-modal
            :show="showCreateAccountModal"
            @close-account-create-modal="closeCreateAccountModal"
          />
          <woot-key-shortcut-modal
            v-if="showShortcutModal"
            @close="closeKeyShortcutModal"
            @clickaway="closeKeyShortcutModal"
          />
          <notification-panel
            v-if="isNotificationPanel"
            @close="closeNotificationPanel"
          />
          <woot-modal
            :show.sync="showAddLabelModal"
            :on-close="hideAddLabelPopup"
          >
            <add-label-modal @close="hideAddLabelPopup" />
          </woot-modal>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '../../components/layout/Sidebar';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel';
import NotificationBell from '../../components/layout/sidebarComponents/NotificationBell';
import AgentDetails from '../../components/layout/sidebarComponents/AgentDetails';
import OptionsMenu from '../../components/layout/sidebarComponents/OptionsMenu.vue';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  components: {
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  mixins: [clickaway],
  data() {
    return {
      isSidebarOpen: false,
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      showOptionsMenu: false,
      isOnDesktop: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      isOnTeamWindow: 'layout/isOnTeamWindow',
    }),
    currentRoute() {
      return ' ';
    },
    sidebarClassName() {
      if (this.isSidebarOpen) {
        return 'off-canvas is-open';
      }
      return 'off-canvas is-transition-push is-closed';
    },
    secondarysidebarClassName() {
      if (this.ShowScondaryoption) {
        return 'off-canvas-secondary is-open';
      }
      return 'off-canvas-seconday is closed';
    },
    contentClassName() {
      if (this.isSidebarOpen) {
        return 'off-canvas-content is-open-left has-transition-push';
      }
      return 'off-canvas-content has-transition-push';
    },
  },
  mounted() {
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 1200) {
        this.isOnDesktop = true;
      } else {
        this.isOnDesktop = false;
      }
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$zerotouchchat.toggle();
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      if (!this.isOnDesktop && this.isSidebarOpen === false) {
        document.getElementsByClassName('non-desktop-msg')[0].hidden = true;
      } else if (!this.isOnDesktop) {
        document.getElementsByClassName('non-desktop-msg')[0].hidden = false;
      }
    },
    closeSidebar() {
      if (this.isSidebarOpen) {
        this.isSidebarOpen = false;
        if (!this.isOnDesktop) {
          document.getElementsByClassName('non-desktop-msg')[0].hidden = true;
        }
      }
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.off-canvas-content.is-open-left {
  transform: translate(0, 0);
}

.user-menu {
  display: flex;
  flex-direction: row;
  height: fit-content;
  border-bottom: 1px solid #f0f4f5;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 13;
  background-color: white;
  width: 100vw;
}

.account {
  display: flex;
  margin-right: 10px;
}

.zero-touch-logo {
  max-width: 100px;
  max-height: 75px;
  width: auto;
  height: auto;
}

.zero-touch-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6e6f73;
  font-size: 10px;
  @media (width <= 470px) {
    font-size: 8px;
  }
}
</style>
