var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row header-wrapper"},[_c('div',{staticClass:"logo-wrapper"},[_c('figure',{staticClass:"client-logo-sec"},[_c('img',{attrs:{"src":_vm.logo,"alt":_vm.headerTitle}})]),_vm._v(" "),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.headerTitle))]),_vm._v(" "),_c('div',{staticClass:"connectwise-message"},[_vm._v("\n        "+_vm._s(_vm.domain)+"\n        "+_vm._s(_vm.psa_status ? ("Linked to ConnectWise company " + _vm.companyName + ".") : '')+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"header-left"},[_c('div',[_c('woot-button',{on:{"click":function($event){return _vm.toggleDown()}}},[_vm._v("\n        Manage "),_c('fluent-icon',{attrs:{"icon":"caret","size":"12"}})],1),_vm._v(" "),(_vm.show)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onClickAway),expression:"onClickAway"}],staticClass:"dropdown-pane manage",class:{ 'dropdown-pane--open': _vm.show }},[_c('woot-button',{attrs:{"is-loading":_vm.isSyncing},on:{"click":_vm.officeConnection}},[_vm._v("\n          Force Sync with Office 365\n        ")]),_vm._v(" "),(!_vm.psa_status)?_c('woot-button',{staticClass:"connectwise-button"},[_vm._v("\n          Connect with ConnectWise Company\n          "),(!_vm.currentUser.is_msp_admin)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:({
              boundariesElement: 'body',
              content: ("You do not have permission to connect to a ConnectWise company. Contact your Admin to connect " + _vm.clientName + " to a ConnectWise Company via the Client section of the Admin Center."),
              classes: 'connectwise-restriction-msg',
            }),expression:"{\n              boundariesElement: 'body',\n              content: `You do not have permission to connect to a ConnectWise company. Contact your Admin to connect ${clientName} to a ConnectWise Company via the Client section of the Admin Center.`,\n              classes: 'connectwise-restriction-msg',\n            }",modifiers:{"top-start":true}}]},[_c('fluent-icon',{staticClass:"info",attrs:{"icon":"info"}})],1):_vm._e(),_vm._v(" "),(_vm.currentUser.is_msp_admin)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:({
              boundariesElement: 'body',
              content: ("Cannot connect with a ConnectWise company. You have not linked " + _vm.clientName + " to a ConnectWise company. First go to " + _vm.clientName + " in ZeroTouchMSP > Admin Center > Clients and link with a ConnectWise company."),
              classes: 'connectwise-restriction-msg',
            }),expression:"{\n              boundariesElement: 'body',\n              content: `Cannot connect with a ConnectWise company. You have not linked ${clientName} to a ConnectWise company. First go to ${clientName} in ZeroTouchMSP > Admin Center > Clients and link with a ConnectWise company.`,\n              classes: 'connectwise-restriction-msg',\n            }",modifiers:{"top-start":true}}]},[_c('fluent-icon',{staticClass:"info",attrs:{"icon":"info"}})],1):_vm._e()]):_vm._e()],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }