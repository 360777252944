<template>
  <div class="view-box columns bg-white client-manage-wrapper">
    <settings-header
      button-route="new"
      :header-title="'All Clients'"
      show-back-button
      :back-button-label="'Back'"
      :back-url="backUrl"
      :show-new-button="false"
    />
    <div
      v-if="uiFlags.isFetchingItem"
      class="text-center p-normal fs-default h-full"
    >
      <spinner size="" />
      <span>Loading Client Data</span>
    </div>
    <div v-else-if="client._id" class="column contact--dashboard-content">
      <div class="row h-full">
        <div class="small-12 medium-12 h-full">
          <client-profile-header
            :logo="client.client_logo"
            :header-title="client.client_name"
            :client-id="client._id"
          />
          <client-stats
            v-if="
              conversationReportsSummary !== null &&
                conversationReportsSummary !== undefined
            "
            :created="chatsCreatedCount"
            :resolved="chatsResolvedCount"
          />
          <chart :client-id="client._id" />
          <user-table :client-id="client._id" />
        </div>
      </div>
    </div>
    <empty-state
      v-if="!uiFlags.isFetchingItem && !client._id"
      :title="'No available clients'"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SettingsHeader from '../../settings/SettingsHeader.vue';
import Spinner from 'shared/components/Spinner';
import Chart from '../components/Chart.vue';
import UserTable from '../components/UserTable';
import ClientProfileHeader from '../components/ClientProfileHeader';
import ClientStats from '../components/ClientStats';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import endOfDay from 'date-fns/endOfDay';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    SettingsHeader,
    Spinner,
    Chart,
    UserTable,
    ClientProfileHeader,
    ClientStats,
    EmptyState,
  },
  mixins: [alertMixin],
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
      groupBy: 'day',
      dateRange: 29,
      type: 'client',
      businessHours: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'clients/getUIFlags',
      conversationReportsSummary: 'clients/getConversationReportsSummary',
    }),
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('NOTES.HEADER.TITLE'),
        },
      ];
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    client() {
      return this.$store.getters['clients/getClient'](this.clientId);
    },
    chatsCreatedCount() {
      return this.conversationReportsSummary.conversations_count;
    },
    chatsResolvedCount() {
      return this.conversationReportsSummary.resolutions_count;
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/clients`;
    },
    to() {
      return this.toCustomDate(new Date());
    },
    from() {
      const diff = this.dateRange;
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
  },
  mounted() {
    this.fetchClientDetails();
    this.fetchReportData();
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    fetchClientDetails() {
      const { clientId: id } = this;
      this.$store.dispatch('clients/show', { id });
    },
    fromCustomDate(date) {
      return getUnixTime(startOfDay(date));
    },
    toCustomDate(date) {
      return getUnixTime(endOfDay(date));
    },
    async fetchReportData() {
      try {
        const { from, to, groupBy, businessHours, type } = this;
        const { clientId: id } = this;
        await this.$store.dispatch('clients/fetchConversationReportsSummary', {
          id,
          from,
          to,
          groupBy,
          businessHours,
          type,
        });
      } catch (error) {
        this.showAlert(
          'We are unable to fetch chats details, please try again'
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.left {
  border-right: 1px solid var(--color-border);
  overflow: auto;
}

.right {
  padding: var(--space-normal);
}

.client-manage-wrapper {
  height: calc(100vh - 76px);
  overflow: auto;
}
</style>
