<template>
  <div class="create-resolve-ticket-wrapper" style="max-height:55rem">
    <woot-modal-header
      :header-title="'Merge with Existing Ticket'"
      class="merge-ticket-margin-top"
    />
    <p class="margin-class search-text-class">
      Search and select existing ticket
    </p>
    <div class="modal-body">
      <div class="row merge-margin-class row-media-query">
        <div class="medium-4 columns input-merge-margin">
          <woot-input
            v-model="ticket"
            class="ticket-id"
            :label="
              $t('CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.TICKET')
            "
          />
        </div>
        <div class="medium-1 columns" />
        <div class="medium-4 columns input-merge-margin">
          <label>
            {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.COMPANY') }}
          </label>
          <multiselect
            v-model="selectedCompany"
            :options="companies"
            track-by="id"
            label="name"
            :multiple="false"
            :loading="uiFlags.isFetchingCompanies"
            :show-labels="false"
            :close-on-select="true"
            :max-height="175"
          />
        </div>
        <p class="search-icon-class" @click="searchTickets()">
          <fluent-icon icon="search-icon" size="30" />
        </p>
      </div>
      <div class="row result-text-class">
        <label>
          {{ $t('CONVERSATION.CREATE_RESOLVE_TICKET.RESULT') }}
        </label>
      </div>
      <br />
      <div v-if="isSearching" class="is-searching">
        <spinner />
        <span> Searching Tickets</span>
      </div>
      <div v-else class="tickets-table-wrapper">
        <div class="tickets-table">
          <table
            style="width:100%;margin-left: 15px;margin-right:15px;"
            class="fixed_header"
          >
            <thead
              style="border-bottom:1px solid #979a9c78;border-top:1px solid #979a9c78"
            >
              <tr>
                <th class="first-table-head">
                  {{
                    $t('CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.TICKET')
                  }}
                </th>
                <th>
                  {{
                    $t(
                      'CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.COMPANY'
                    )
                  }}
                </th>
                <th>
                  {{
                    $t('CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.STATUS')
                  }}
                </th>
                <th>
                  {{
                    $t(
                      'CONVERSATION.CREATE_RESOLVE_TICKET.MERGE_TICKET.SUMMARY'
                    )
                  }}
                </th>
                <th>&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ticket in searchedTickets"
                :key="ticket.id"
                style="border-bottom:1px solid #979a9c78"
              >
                <td>{{ ticket.id }}</td>
                <td>{{ ticket.company.name }}</td>
                <td>{{ ticket.status.name }}</td>
                <td>{{ ticket.summary }}</td>
                <td>
                  <woot-button
                    color-scheme="primary"
                    size="small"
                    class="select-button"
                    :disabled="
                      ticket.status.name === 'Closed (resolved)' ? true : false
                    "
                    @click="choosedTicket(ticket.id)"
                  >
                    {{ selectedTicket == ticket.id ? 'Selected' : 'Select' }}
                  </woot-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="merge-margin-class footer merge-card-footer">
        <div class="row">
          <div class="medium-5 columns">
            <woot-button
              :is-disabled="!selectedTicket"
              :is-loading="isSubmitting"
              @click="mergeTicket"
            >
              Submit
            </woot-button>
            <woot-button class="button clear" @click.prevent="onClose">
              {{ $t('AUTOMATION.ADD.CANCEL_BUTTON_TEXT') }}
            </woot-button>
          </div>
          <div class="medium-7 columns">
            <p class="margin-class close-ticket-search">
              You will not be able to merge with a closed ticket
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
[type='text'] {
  height: 4.5rem;
}
.button.clear.secondary {
  z-index: 1;
}
.search-icon-class:hover {
  cursor: pointer;
}
.select-button {
  margin: 8px;
}
.is-searching {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}
.fixed_header {
  width: 400px;
  table-layout: fixed;
  border-collapse: collapse;
}
.tickets-table {
  max-height: 20em;
  overflow-y: auto;
  overflow-x: hidden;

  table {
    @media screen and (max-width: 450px) {
      font-size: 1rem;
      .button.small {
        font-size: 1rem;
      }
    }
  }
}
.tickets-table-wrapper {
  width: 96%;
}
</style>
<script>
import { mapGetters } from 'vuex';
import Connectwise from '../../api/connectwise';
import wootConstants from '../../constants';
import { CMD_RESOLVE_CONVERSATION } from '../../routes/dashboard/commands/commandBarBusEvents';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedCompany: [],
      ticket: '',
      searchedTickets: [],
      selectedTicket: null,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      isSearching: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'connectwise/getUiFlags',
      currentUser: 'getCurrentUser',
      companies: 'connectwise/getCompanies',
      currentChat: 'getSelectedChat',
    }),
  },
  mounted() {
    this.$store.dispatch('connectwise/companies');
    bus.$on(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);
  },
  destroyed() {
    bus.$off(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async searchTickets() {
      if (this.selectedCompany?.id || this.ticket) {
        this.isSearching = true;
        this.searchedTickets = [];
        try {
          const response = await Connectwise.searchTickets(
            this.selectedCompany?.id,
            this.ticket
          );
          this.searchedTickets = response.data.data;
        } catch (error) {
          this.showAlert(error);
        } finally {
          this.isSearching = false;
        }
      }
    },
    choosedTicket(ticket) {
      this.selectedTicket = ticket;
    },
    isDisabled(status, ticketId) {
      if (status === 'Closed (resolved)' || this.selectedTicket === ticketId) {
        return true;
      }
      return false;
    },
    async mergeTicket() {
      this.isSubmitting = true;
      try {
        await Connectwise.mergeTicket(this.selectedTicket, this.currentChat.id);
        this.$emit('close');
        this.showAlert('Successfully merged with service ticket');
        this.onCmdResolveConversation();
      } catch (error) {
        this.showAlert(error.response.data.message || 'server error');
      } finally {
        this.isSubmitting = false;
      }
    },
    onCmdResolveConversation() {
      this.toggleStatus(this.STATUS_TYPE.RESOLVED);
    },
    toggleStatus(status, snoozedUntil) {
      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status,
          snoozedUntil,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
        });
    },
  },
};
</script>
