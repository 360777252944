var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body columns content-box small-9"},[_c('empty-state',{attrs:{"title":_vm.$t('INBOX_MGMT.FINISH.TITLE'),"message":_vm.message,"button-text":_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT')}},[_c('div',{staticClass:"medium-12 columns text-center"},[_c('div',{staticClass:"website--code"},[(_vm.currentInbox.web_widget_script)?_c('woot-code',{attrs:{"script":_vm.currentInbox.web_widget_script}}):_vm._e()],1),_vm._v(" "),(_vm.isTeamsInbox)?_c('div',{staticClass:"medium-10 small-offset-1 bg-light it-help"},[_c('img',{attrs:{"src":require("dashboard/assets/images/it-help-app.png"),"alt":"it-help"}}),_vm._v(" "),_c('h6',[_vm._v("IT Help")]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: _vm.currentInbox.is_it_help_app_deploying
              ? 'IT Help Teams app is deploying......'
              : null,
          }),expression:"{\n            content: currentInbox.is_it_help_app_deploying\n              ? 'IT Help Teams app is deploying......'\n              : null,\n          }",modifiers:{"bottom":true}}]},[_c('woot-button',{attrs:{"is-loading":_vm.uiFlags.isUpdating,"is-disabled":_vm.currentInbox.is_it_help_app_deploying},on:{"click":function($event){return _vm.deployItHelpApp()}}},[_vm._v("\n            Deploy IT Help Microsoft Teams App\n          ")])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isATwilioInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isWhatsAppCloudInbox)?_c('div',{staticClass:"medium-6 small-offset-3"},[_c('p',{staticClass:"config--label"},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL'))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}),_vm._v(" "),_c('p',{staticClass:"config--label"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
            ))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.provider_config.webhook_verify_token}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isALineInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isASmsInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"medium-6 small-offset-3"},[(_vm.isAEmailInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.forward_to_email}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"footer"},[_c('router-link',{staticClass:"button hollow primary settings-button",attrs:{"to":{
            name: 'settings_inbox_show',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.MORE_SETTINGS'))+"\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"button success",attrs:{"to":{
            name: 'inbox_dashboard',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.buttonText)+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }