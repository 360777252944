export const getters = {
  getCompanies($state) {
    return $state.companies;
  },
  getUiFlags($state) {
    return $state.uiFlags;
  },
  getBoards($state) {
    return $state.boards;
  },
  getContacts($state) {
    return $state.contacts;
  },
  getSites($state) {
    return $state.sites;
  },
  getTypes($state) {
    return $state.types;
  },
  getStatuses($state) {
    return $state.statuses;
  },
};
