<template>
  <div class="wizard-body columns content-box small-9">
    <empty-state
      :title="$t('INBOX_MGMT.FINISH.TITLE')"
      :message="message"
      :button-text="$t('INBOX_MGMT.FINISH.BUTTON_TEXT')"
    >
      <div class="medium-12 columns text-center">
        <div class="website--code">
          <woot-code
            v-if="currentInbox.web_widget_script"
            :script="currentInbox.web_widget_script"
          />
        </div>
        <div
          v-if="isTeamsInbox"
          class="medium-10 small-offset-1 bg-light it-help"
        >
          <img src="~dashboard/assets/images/it-help-app.png" alt="it-help" />
          <h6>IT Help</h6>
          <div
            v-tooltip.bottom="{
              content: currentInbox.is_it_help_app_deploying
                ? 'IT Help Teams app is deploying......'
                : null,
            }"
          >
            <woot-button
              :is-loading="uiFlags.isUpdating"
              :is-disabled="currentInbox.is_it_help_app_deploying"
              @click="deployItHelpApp()"
            >
              Deploy IT Help Microsoft Teams App
            </woot-button>
          </div>
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isATwilioInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div v-if="isWhatsAppCloudInbox" class="medium-6 small-offset-3">
          <p class="config--label">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL') }}
          </p>
          <woot-code lang="html" :script="currentInbox.callback_webhook_url" />
          <p class="config--label">
            {{
              $t(
                'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
              )
            }}
          </p>
          <woot-code
            lang="html"
            :script="currentInbox.provider_config.webhook_verify_token"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isALineInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isASmsInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isAEmailInbox"
            lang="html"
            :script="currentInbox.forward_to_email"
          />
        </div>
        <div class="footer">
          <router-link
            class="button hollow primary settings-button"
            :to="{
              name: 'settings_inbox_show',
              params: { inboxId: this.$route.params.inbox_id },
            }"
          >
            {{ $t('INBOX_MGMT.FINISH.MORE_SETTINGS') }}
          </router-link>
          <router-link
            class="button success"
            :to="{
              name: 'inbox_dashboard',
              params: { inboxId: this.$route.params.inbox_id },
            }"
          >
            {{ buttonText }}
          </router-link>
        </div>
      </div>
    </empty-state>
  </div>
</template>

<script>
import configMixin from 'shared/mixins/configMixin';
import EmptyState from '../../../../components/widgets/EmptyState';
import WootButton from '../../../../components/ui/WootButton';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    EmptyState,
    WootButton,
  },
  mixins: [configMixin, alertMixin],
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    currentInbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
    },
    isATwilioInbox() {
      return this.currentInbox.channel_type === 'Channel::TwilioSms';
    },
    isAEmailInbox() {
      return this.currentInbox.channel_type === 'Channel::Email';
    },
    isALineInbox() {
      return this.currentInbox.channel_type === 'Channel::Line';
    },
    isASmsInbox() {
      return this.currentInbox.channel_type === 'Channel::Sms';
    },
    isWhatsAppCloudInbox() {
      return (
        this.currentInbox.channel_type === 'Channel::Whatsapp' &&
        this.currentInbox.provider === 'whatsapp_cloud'
      );
    },
    isTeamsInbox() {
      return this.currentInbox.channel_type === 'Channel::Team';
    },
    message() {
      if (this.isATwilioInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isASmsInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.SMS.BANDWIDTH.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isALineInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isWhatsAppCloudInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isAEmailInbox) {
        return this.$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.FINISH_MESSAGE');
      }

      if (this.currentInbox.web_widget_script) {
        return this.$t('INBOX_MGMT.FINISH.WEBSITE_SUCCESS');
      }

      if (this.isTeamsInbox) {
        return this.$t('INBOX_MGMT.ADD.TEAMS_CHANNEL.FINISH.MESSAGE');
      }

      return this.$t('INBOX_MGMT.FINISH.MESSAGE');
    },
    buttonText() {
      if (this.isTeamsInbox) {
        return this.$t('INBOX_MGMT.ADD.TEAMS_CHANNEL.FINISH.BUTTON_TEXT');
      }
      return this.$t('INBOX_MGMT.FINISH.BUTTON_TEXT');
    },
  },
  methods: {
    async deployItHelpApp() {
      try {
        await this.$store.dispatch(
          'inboxes/deployItHelpApp',
          this.currentInbox
        );
        this.showAlert(
          'IT Help Teams app is being deployed! It may take up to 24 hours to deploy to all clients'
        );
      } catch (error) {
        this.showAlert(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.website--code {
  margin: $space-normal auto;
  max-width: 70%;
}

.footer {
  display: flex;
  justify-content: center;
}

.settings-button {
  margin-right: var(--space-small);
}

.config--label {
  color: var(--b-600);
  font-weight: var(--font-weight-medium);
  margin-top: var(--space-large);
}
</style>
