export const getters = {
  getClients($state) {
    return $state.sortOrder.map(clientId => $state.clients[clientId]);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getMeta($state) {
    return $state.meta;
  },
  getNewClients($state) {
    return $state.newClients;
  },
  getClient: $state => id => {
    const client = $state.clients[id];
    return client || {};
  },
  getUsers($state) {
    return $state.users;
  },
  getConversationReports($state) {
    return $state.conversationReports;
  },
  getConversationReportsSummary($state) {
    return $state.conversationReportsSummary;
  },
  getUsersMeta($state) {
    return $state.users_meta;
  },
};
