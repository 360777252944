<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div v-if="createResolveButtonCard" class="create-resolve-ticket-wrapper">
      <woot-modal-header :header-title="'Resolve & Create a Ticket'" />
      <div class="modal-body create-resolve-model">
        <div class="row margin-class">
          <input
            v-model="ticketButton"
            type="radio"
            name="ticketStatus"
            value="create"
            @click="nextButtonEnable('create')"
          />
          <span class="font-class">&nbsp;&nbsp;Create a new ticket</span>
        </div>
        <br />
        <div class="row margin-class">
          <input
            v-model="ticketButton"
            type="radio"
            name="ticketStatus"
            value="merge"
            @click="nextButtonEnable('merge')"
          />
          <span class="font-class">&nbsp;&nbsp;Merge with existing ticket</span>
        </div>
        <br />
        <div class="row margin-class footer">
          <woot-button
            :is-disabled="nextButtonDisable"
            @click="resolveOrCreateTicket"
          >
            Next
          </woot-button>
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('AUTOMATION.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </div>
    </div>
    <create-ticket v-if="createTicketCard" @close="onClose" />
    <merge-ticket v-if="mergeTicketCard" @close="onClose" />
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import CreateTicket from './CreateTickets.vue';
import MergeTicket from './MergeTickets.vue';
export default {
  components: {
    MergeTicket,
    CreateTicket,
  },
  data() {
    return {
      show: true,
      ticketButton: '',
      nextButtonDisable: true,
      createResolveButtonCard: true,
      createTicketCard: false,
      disableCreateButton: true,
      disableMergeButton: true,
      mergeTicketCard: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'clients/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    resolveOrCreateTicket() {
      // eslint-disable-next-line no-alert
      this.createResolveButtonCard = false;
      // eslint-disable-next-line eqeqeq
      if (this.ticketButton == 'create') {
        this.createTicketCard = true;
      } else {
        this.mergeTicketCard = true;
      }
    },
    createTicket() {
      // eslint-disable-next-line no-console
    },
    nextButtonEnable(value) {
      this.nextButtonDisable = false;
      this.ticketButton = value;
      // eslint-disable-next-line no-console
    },
  },
};
</script>
<style lang="scss">
.resolve-actions {
  @media screen and (max-width: 1080px) {
    .row-media-query {
      display: flex !important;
      flex-direction: column;
    }
    .input-margin {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .input-merge-margin {
      margin-right: 21px !important;
    }
    .modal-container {
      background-color: #fff;
      border-radius: 0.4rem;
      max-height: 95%;
      overflow: auto;
      position: relative;
      width: 96%;
    }
    .first-table-head {
      width: 22% !important;
    }
    .fixed_header {
      width: 400px;
      table-layout: fixed;
      border-collapse: collapse;
    }
    .fixed_header tbody {
      display: block;
      width: 100%;
      overflow: auto;
      max-height: 20rem;
    }
    .search-icon-class {
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
    .fixed_header thead tr {
      display: block;
    }
    .fixed_header th,
    .fixed_header td {
      padding: 5px;
      text-align: left;
      width: 200px;
    }
  }
}
.create-resolve-ticket-wrapper {
  .margin-class {
    margin-left: 32px;
    font-size: 16px;
  }

  .font-class {
    font-weight: 500;
  }

  .merge-class-header {
    margin-left: 6px;
  }

  .margin-class-create-ticket {
    margin-left: 47px;
    font-size: 16px;
  }

  .merge-margin-class {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 16px;
  }

  .result-text-class {
    margin-left: 25px;
    font-size: 16px;
  }

  .create-resolve-model {
    margin-top: 50px;
  }

  .close-ticket-search {
    color: grey;
    font-size: 12px;
    margin-top: 21px;
  }

  .footer {
    gap: 1rem;
    margin-bottom: 16px;
  }

  .page-sub-title {
    font-size: 2.5rem !important;
  }

  .search-icon-class {
    margin-top: 29px;
    margin-left: 64px;
  }

  .search-text-class {
    color: grey;
    font-size: 12px;
    margin-top: -6px;
  }

  .merge-ticket-border {
    border-top: 1px solid #e5dfdf;
  }

  .merge-card-footer {
    margin-top: 50px;
  }

  input[type='radio'] {
    margin-top: 1px;
    width: 18px;
    height: 20px;
  }

  .merge-ticket-margin-top {
    margin-top: -20px;
  }
}
</style>
