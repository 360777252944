export default {
  computed: {
    hostURL() {
      return window.zerotouchChatConfig.hostURL;
    },
    vapidPublicKey() {
      return window.zerotouchChatConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.zerotouchChatConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.zerotouchChatConfig.isEnterprise === 'true';
    },
  },
};
