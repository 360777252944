import { render, staticRenderFns } from "./ClientManageView.vue?vue&type=template&id=22f52b06&scoped=true&"
import script from "./ClientManageView.vue?vue&type=script&lang=js&"
export * from "./ClientManageView.vue?vue&type=script&lang=js&"
import style0 from "./ClientManageView.vue?vue&type=style&index=0&id=22f52b06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f52b06",
  null
  
)

export default component.exports