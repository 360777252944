/* eslint-disable no-undef */
import ApiClient from './ApiClient';

export const buildClientParams = (page, sortAttr, search) => {
  let params = `&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&searchtext=${search}`;
  }
  return params;
};
class ClientAPI extends ApiClient {
  constructor() {
    super('clients', { accountScoped: true });
  }

  get(page, sortAttr = '', search = '') {
    let requestURL = `${this.url}?${buildClientParams(page, sortAttr, search)}`;
    return axios.get(requestURL);
  }

  getNewClients() {
    return axios.get(`${this.url}/newclients`);
  }

  addClients({ _id: client_id, office_365_id, client_name }) {
    return axios.post(`${this.url}`, { client_id, office_365_id, client_name });
  }

  getUsers(client_id, page = 1) {
    return axios.get(`${this.url}/${client_id}/users?page=${page}`);
  }
}

export default new ClientAPI();
