/* eslint-disable no-underscore-dangle */
import types from '../../mutation-types';
import ClientAPI from '../../../api/clients';
import ReportAPI from '../../../api/reports';
import ContactAPI from '../../../api/contacts';

export const actions = {
  get: async ({ commit }, { search = '', page = 1, sortAttr } = {}) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetching: true });
    try {
      const { data } = await ClientAPI.get(page, sortAttr, search);
      const payload = data.payload;
      const meta = data.meta;
      commit(types.CLEAR_CLIENTS);
      commit(types.SET_CLIENTS, payload);
      commit(types.SET_CLIENTS_META, meta);
      commit(types.SET_CLIENT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CLIENT_UI_FLAG, { isFetching: false });
    }
  },

  getNewClients: async ({ commit }) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetchingNewClients: true });
    try {
      const { data } = await ClientAPI.getNewClients();
      commit(types.CLEAR_NEW_CLIENTS);
      if (data.data !== undefined) {
        commit(types.SET_NEW_CLIENTS, data.data);
      }
      commit(types.SET_CLIENT_UI_FLAG, { isFetchingNewClients: false });
    } catch (error) {
      commit(types.SET_CLIENT_UI_FLAG, { isFetchingNewClients: false });
    }
  },

  isAdding: async ({ commit }, isAdding) => {
    commit(types.SET_CLIENT_UI_FLAG, { isAdding: isAdding });
  },

  show: async ({ commit }, { id }) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await ClientAPI.show(id);
      commit(types.SET_CLIENT_ITEM, response.data.payload);
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },

  getUsers: async ({ commit }, { id, page }) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetchingUsers: true });
    try {
      const response = await ClientAPI.getUsers(id, page);
      commit(types.CLEAR_CLIENT_USERS);
      commit(types.SET_CLIENT_USERS, response.data.payload);
      commit(types.SET_CLIENT_USERS_META, response.data.meta);
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingUsers: false,
      });
    } catch (error) {
      commit(types.CLEAR_CLIENT_USERS);
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingUsers: false,
      });
    }
  },

  getConversationReports: async ({ commit }, reportObj) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetchingConversationReport: true });
    try {
      await ReportAPI.getReports(
        reportObj.metric,
        reportObj.from,
        reportObj.to,
        reportObj.type,
        reportObj.id,
        reportObj.groupBy,
        reportObj.businessHours
      ).then(accountReport => {
        let { data } = accountReport;
        data = data.filter(
          el =>
            reportObj.to - el.timestamp > 0 &&
            el.timestamp - reportObj.from >= 0
        );
        commit(types.CLEAR_CLIENT_CONVERSATION_REPORTS);
        commit(types.SET_CLIENT_CONVERSATION_REPORTS, data);
        commit(types.SET_CLIENT_UI_FLAG, {
          isFetchingConversationReport: false,
        });
      });
    } catch (error) {
      commit(types.CLEAR_CLIENT_CONVERSATION_REPORTS);
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingConversationReport: false,
      });
    }
  },

  fetchConversationReportsSummary: async ({ commit }, reportObj) => {
    commit(types.SET_CLIENT_UI_FLAG, { isFetchingConversationReport: true });
    try {
      await ReportAPI.getSummary(
        reportObj.from,
        reportObj.to,
        reportObj.type,
        reportObj.id,
        reportObj.groupBy,
        reportObj.businessHours
      ).then(accountReportSummary => {
        let { data } = accountReportSummary;
        commit(types.SET_CLIENT_CONVERSATION_REPORTS_SUMMARY, data);
        commit(types.SET_CLIENT_UI_FLAG, {
          isFetchingConversationReport: false,
        });
      });
    } catch (error) {
      commit(types.CLEAR_CLIENT_CONVERSATION_REPORTS_SUMMARY);
      commit(types.SET_CLIENT_UI_FLAG, {
        isFetchingConversationReport: false,
      });
    }
  },

  updateClientUserStatus: async ({ commit }, userObj) => {
    try {
      const response = await ContactAPI.update(userObj.id, {
        status: userObj.status,
      });
      commit(types.UPDATE_CLIENT_USER_STATUS, response.data.payload);
    } catch (error) {
      throw new Error(error);
    }
  },
};
