/* eslint-disable func-names */
/**
 * Writes a text string to the system clipboard.
 *
 * @async
 * @param {string} text text to be written to the clipboard
 * @throws {Error} unable to copy text to clipboard
 */
import store from './../../dashboard/store/index';
export const copyTextToClipboard = async text => {
  try {
    if (store.getters['layout/isOnTeamWindow']) {
      document.addEventListener(
        'copy',
        function(e) {
          e.clipboardData.setData('text/plain', text);
          e.preventDefault();
        },
        true
      );
      document.execCommand('copy');
    } else {
      await navigator.clipboard.writeText(text);
    }
  } catch (error) {
    throw new Error(`Unable to copy text to clipboard: ${error.message}`);
  }
};
