<template>
  <div
    class="small-6 medium-4 large-3 columns channel"
    :class="{
      'gray-out': (channel.key === 'teams' && hasTeamInbox) || !isActive,
    }"
    @click="onItemClick"
  >
    <img
      v-if="channel.key === 'facebook'"
      src="~dashboard/assets/images/channels/messenger.png"
    />
    <img
      v-if="channel.key === 'twitter'"
      src="~dashboard/assets/images/channels/twitter.png"
    />
    <img
      v-if="channel.key === 'telegram'"
      src="~dashboard/assets/images/channels/telegram.png"
    />
    <img
      v-if="channel.key === 'api' && !channel.thumbnail"
      src="~dashboard/assets/images/channels/api.png"
    />
    <img
      v-if="channel.key === 'api' && channel.thumbnail"
      :src="channel.thumbnail"
    />
    <img
      v-if="channel.key === 'email'"
      src="~dashboard/assets/images/channels/email.png"
    />
    <img
      v-if="channel.key === 'line'"
      src="~dashboard/assets/images/channels/line.png"
    />
    <img
      v-if="channel.key === 'website'"
      src="~dashboard/assets/images/channels/website.png"
    />
    <img
      v-if="channel.key === 'sms'"
      src="~dashboard/assets/images/channels/sms.png"
    />
    <img
      v-if="channel.key === 'whatsapp'"
      src="~dashboard/assets/images/channels/whatsapp.png"
    />
    <img
      v-if="channel.key === 'teams'"
      src="~dashboard/assets/images/channels/teams.png"
    />
    <h3 class="channel__title">
      {{ channel.name }}
    </h3>
    <span
      v-if="channel.key === 'teams' && hasTeamInbox"
      class="info"
      @click.stop
    >
      <fluent-icon
        v-tooltip.top="{
          content: `You are only allowed to create 1 Microsoft Teams inbox. To modify your existing Microsoft Teams inbox go to the &quot;Inboxes&quot; section.`,
          classes: 'team-restriction-msg',
        }"
        icon="info"
        class="info-icon"
      />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
    hasTeamInbox: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      const { key } = this.channel;
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }
      // if (key === 'facebook') {
      //   return this.enabledFeatures.channel_facebook;
      // }
      // if (key === 'twitter') {
      //   return this.enabledFeatures.channel_twitter;
      // }
      // if (key === 'email') {
      //   return this.enabledFeatures.channel_email;
      // }
      if (key === 'facebook' || key === 'twitter' || key === 'email') {
        return false;
      }

      return [
        'teams',
        // 'website',
        // 'twilio',
        // 'api',
        // 'whatsapp',
        // 'sms',
        // 'telegram',
        // 'line',
      ].includes(key);
    },
  },
  methods: {
    onItemClick() {
      if (this.isActive) {
        this.$emit('channel-item-click', this.channel.key);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.info-icon {
  color: var(--w-500);
  width: 30px;
  height: 25px;
}
.info {
  pointer-events: auto;
  display: flex;
  justify-content: end;
}
</style>
