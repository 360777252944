import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  meta: {
    count: 0,
    currentPage: 1,
    pageSize: 15,
  },
  users_meta: {
    count: 0,
    currentPage: 1,
    pageSize: 15,
  },
  clients: [],
  newClients: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isFetchingNewClients: false,
    isFetchingUsers: false,
    isFetchingConversationReport: false,
    isAdding: false,
  },
  sortOrder: [],
  users: [],
  conversationReports: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
