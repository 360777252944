import deployZeroTouchChatAPI from '../../api/microsoftTeamsDeploy';

const state = {};

export const getters = {};

export const actions = {
  async deployZeroTouchChatToUsers(_, accountId) {
    await deployZeroTouchChatAPI.deployZeroTouchChatApp(accountId);
  },
};

export const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
