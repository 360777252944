var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row content-box full-height"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-8 columns with-right-space "},[_c('div',{staticClass:"wizard-body columns content-box small-9 teamsbox"},[_c('div',{staticClass:"medium-12 columns text-center"},[_c('div',{staticClass:"teamsapp"},[_c('img',{staticClass:"zerotouchteams",attrs:{"src":'/dashboard/images/integrations/zerotouchchat.png'}}),_vm._v(" "),_c('h6',[_vm._v("ZeroTouch Chat")])]),_vm._v(" "),_c('woot-button',{staticClass:"deploy-microsoft-teams-button",on:{"click":_vm.deployZerotouchChatToUsers}},[_vm._v("\n            Deploy ZeroTouch Chat for Microsoft Teams App\n          ")]),_vm._v(" "),_c('div',[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.$t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.MESSAGE')
              ),expression:"\n                $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.MESSAGE')\n              "}]})]),_vm._v(" "),_c('div',[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                _vm.$t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.NOTE')
              ),expression:"\n                $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.NOTE')\n              "}],staticClass:"deployment-time"})])],1)])]),_vm._v(" "),_c('div',{staticClass:"small-4 columns"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.useInstallationName(
            _vm.$t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.SIDEBAR_TXT'),
            _vm.globalConfig.installationName
          )
        ),expression:"\n          useInstallationName(\n            $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.SIDEBAR_TXT'),\n            globalConfig.installationName\n          )\n        "}]})])])])}
var staticRenderFns = []

export { render, staticRenderFns }