/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_CLIENT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_CLIENTS]: ($state, data) => {
    const sortOrder = data.map(client => {
      Vue.set($state.clients, client._id, {
        ...($state.clients[client._id] || {}),
        ...client,
      });
      return client._id;
    });
    $state.sortOrder = sortOrder;
  },

  [types.SET_NEW_CLIENTS]: ($state, data) => {
    $state.newClients = data;
  },

  [types.CLEAR_CLIENTS]: $state => {
    Vue.set($state, 'clients', {});
    Vue.set($state, 'sortOrder', []);
  },

  [types.CLEAR_NEW_CLIENTS]: $state => {
    Vue.set($state, 'newClients', []);
  },

  [types.SET_CLIENTS_META]: ($state, data) => {
    const { count, current_page: currentPage, page_size: pageSize } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
    Vue.set($state.meta, 'pageSize', pageSize);
  },

  [types.SET_CLIENT_ITEM]: ($state, data) => {
    Vue.set($state.clients, data._id, {
      ...($state.clients[data._id] || {}),
      ...data,
    });

    if (!$state.sortOrder.includes(data._id)) {
      $state.sortOrder.push(data._id);
    }
  },

  [types.SET_CLIENT_USERS_META]: ($state, data) => {
    const { count, current_page: currentPage, page_size: pageSize } = data;

    Vue.set($state.users_meta, 'count', count);
    Vue.set($state.users_meta, 'currentPage', currentPage);
    Vue.set($state.users_meta, 'pageSize', pageSize);
  },

  [types.SET_CLIENT_USERS]: ($state, data) => {
    Vue.set($state, 'users', data);
  },

  [types.CLEAR_CLIENT_USERS]: $state => {
    Vue.set($state, 'users', []);
  },

  [types.SET_CLIENT_CONVERSATION_REPORTS]: ($state, data) => {
    Vue.set($state, 'conversationReports', data);
  },

  [types.CLEAR_CLIENT_CONVERSATION_REPORTS]: $state => {
    Vue.set($state, 'conversationReports', []);
  },

  [types.UPDATE_CLIENT_USER_STATUS]: ($state, userObj) => {
    const index = $state.users.findIndex(todo => todo.id === userObj.id);
    if (index !== -1) {
      const users = $state.users;
      users[index] = userObj;
      $state.users = [...users];
    }
  },

  [types.SET_CLIENT_CONVERSATION_REPORTS_SUMMARY]: ($state, data) => {
    Vue.set($state, 'conversationReportsSummary', data);
  },

  [types.CLEAR_CLIENT_CONVERSATION_REPORTS_SUMMARY]: $state => {
    Vue.set($state, 'conversationReportsSummary', []);
  },
};
