<template>
  <div class="row header-wrapper">
    <div class="logo-wrapper">
      <figure class="client-logo-sec">
        <img :src="logo" :alt="headerTitle" />
      </figure>
      <div>
        <h3>{{ headerTitle }}</h3>
        <div class="connectwise-message">
          {{ domain }}
          {{
            psa_status ? `Linked to ConnectWise company ${companyName}.` : ''
          }}
        </div>
      </div>
    </div>
    <div class="header-left">
      <div>
        <woot-button @click="toggleDown()">
          Manage <fluent-icon icon="caret" size="12" />
        </woot-button>
        <div
          v-if="show"
          v-on-clickaway="onClickAway"
          class="dropdown-pane manage"
          :class="{ 'dropdown-pane--open': show }"
        >
          <woot-button :is-loading="isSyncing" @click="officeConnection">
            Force Sync with Office 365
          </woot-button>
          <woot-button v-if="!psa_status" class="connectwise-button">
            Connect with ConnectWise Company
            <p
              v-if="!currentUser.is_msp_admin"
              v-tooltip.top-start="{
                boundariesElement: 'body',
                content: `You do not have permission to connect to a ConnectWise company. Contact your Admin to connect ${clientName} to a ConnectWise Company via the Client section of the Admin Center.`,
                classes: 'connectwise-restriction-msg',
              }"
            >
              <fluent-icon class="info" icon="info" />
            </p>
            <p
              v-if="currentUser.is_msp_admin"
              v-tooltip.top-start="{
                boundariesElement: 'body',
                content: `Cannot connect with a ConnectWise company. You have not linked ${clientName} to a ConnectWise company. First go to ${clientName} in ZeroTouchMSP > Admin Center > Clients and link with a ConnectWise company.`,
                classes: 'connectwise-restriction-msg',
              }"
            >
              <fluent-icon class="info" icon="info" />
            </p>
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import { API, setHeader } from '../../../../../msp/helpers/axios';

export default {
  mixins: [clickaway, alertMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isSyncing: false,
      psa_status: false,
      clientName: '',
      domain: '',
      companyName: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {
    this.checkPsaConected();
  },
  methods: {
    toggleDown() {
      this.show = !this.show;
    },
    onClickAway() {
      this.show = false;
    },
    async officeConnection() {
      this.isSyncing = true;
      let response = await fetch(
        `${window.zerotouchChatConfig.mspUrl}/users/syncoffice365`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `${this.currentUser.access_token}`,
          },
          body: JSON.stringify({ clientId: `${this.clientId}` }),
        }
      );
      let data = await response.json();
      this.isSyncing = false;
      if (data.status.code === 200) {
        this.showAlert('Office 365 has been successfully synced!');
      } else {
        this.showAlert(
          `Failed to sync with Office 365. Check that your credentials are correct. Error: ${data.status.code} Response: ${data.status.message}`
        );
      }
      this.show = false;
    },
    async checkPsaConected() {
      setHeader(this.currentUser.access_token);
      await API.post('clientlist', {
        clientId: this.clientId,
      })
        .then(response => {
          // eslint-disable-next-line dot-notation
          this.psa_status = response.data.data[0].data[0].psa_connected;
          this.clientName = response.data.data[0].data[0].client_name;
          this.domain = response.data.data[0].data[0].client_website;
          this.companyName =
            response.data.data[0].data[0].connectwise_company_name;
        })
        .catch(error => {
          this.showAlert(error.response.data.message || 'server error');
        });
    },
  },
};
</script>
<style lang="scss" scoped>
figure.client-logo-sec {
  width: 120px;
  min-width: 120px;
  height: 120px;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 0;
}

figure.client-logo-sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.logo-wrapper {
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
}
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-left {
  margin-right: 10px;
  position: relative;
  padding: 1rem;
  .button {
    svg {
      font-size: 8px;
    }
  }
}
.dropdown-pane {
  right: 1rem;
  white-space: nowrap;
  z-index: var(--z-index-very-high);
  padding: 0.5%;
  padding-bottom: 0%;
  border-radius: 0.5rem;
  border: 1px solid #8080805e;
  display: flex;
  flex-direction: column;
  .button {
    background-color: white;
    color: black;
    min-width: 12rem;
    padding-left: 1rem;
    text-align: left;
  }
  .button:hover {
    background-color: var(--s-50);
  }
}
.info {
  color: var(--w-500);
}

.connectwise-button {
  background: var(--disable-color) !important;
  .button__content {
    display: flex;
  }
}

.connectwise-message {
  font-size: 10px;
  p {
    margin-bottom: 0px !important;
  }
}
</style>
