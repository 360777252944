<template>
  <div class="clients-page row">
    <div class="left-wrap medium-9">
      <Header
        :search-query="searchQuery"
        :header-title="'Clients'"
        :on-input-search="onInputSearch"
        :on-search-submit="onSearchSubmit"
        @toggle-add-client="toggleAddClient"
      />
      <clients-table
        :clients="records"
        :is-loading="uiFlags.isFetching"
        :sort-config="sortConfig"
        @on-sort-change="onSortChange"
      />
      <table-footer
        :current-page="Number(meta.currentPage)"
        :total-count="Number(meta.count)"
        :page-size="meta.pageSize"
        :class-names="'client-footer'"
        @page-change="onPageChange"
      />
    </div>
    <add-client v-if="showAddClientModal" @close="toggleAddClient" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from './Header';
import ClientsTable from './ClientsTable';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import AddClient from './AddClient';

const DEFAULT_PAGE = 1;

export default {
  components: {
    Header,
    ClientsTable,
    TableFooter,
    AddClient,
  },
  data() {
    return {
      searchQuery: '',
      sortConfig: {},
      showFiltersModal: false,
      showAddClientModal: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'clients/getClients',
      uiFlags: 'clients/getUIFlags',
      meta: 'clients/getMeta',
    }),
  },
  mounted() {
    this.fetchClients(this.$route.query.page ? this.$route.query.page : 1);
  },
  methods: {
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },
    toggleAddClient() {
      this.showAddClientModal = !this.showAddClientModal;
    },
    onInputSearch(event) {
      this.searchQuery = event.target.value;
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      return sortAttr;
    },
    onSortChange(params) {
      this.sortConfig = params;
      this.fetchClients(DEFAULT_PAGE);
    },
    fetchClients(page) {
      this.updatePageParam(page);
      let value = '';
      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }
      const requestParams = {
        page,
        sortAttr: this.getSortAttribute(),
      };
      if (!value) {
        this.$store.dispatch('clients/get', requestParams);
      } else {
        this.$store.dispatch('clients/get', {
          search: encodeURIComponent(value),
          ...requestParams,
        });
      }
    },
    onSearchSubmit() {
      this.fetchClients(DEFAULT_PAGE);
    },
    onPageChange(page) {
      this.fetchClients(page);
    },
  },
};
</script>
<style lang="scss">
.clients-page .modal-container {
  overflow: unset;
  max-height: 70vh;
}
.client-footer {
  width: 100vw;
}
</style>
