const state = {
  isOnDesktop: true,
  isOnTeamWindow:
    navigator.userAgent.indexOf('Teams') !== -1 ||
    window.name === 'embedded-page-container',
};

const getters = {
  isOnDesktop($state) {
    return $state.isOnDesktop;
  },
  isOnTeamWindow($state) {
    return $state.isOnTeamWindow;
  },
};

const mutations = {
  ['SET_IS_ON_DESKTOP']: ($state, isOnDesktop) => {
    $state.isOnDesktop = isOnDesktop;
  },
  ['SET_IS_ON_TEAM_WINDOW']: ($state, isOnTeamWindow) => {
    $state.isOnTeamWindow = isOnTeamWindow;
  },
};

const actions = {
  setIsOnDesktop({ commit }, isOnDesktop) {
    commit('SET_IS_ON_DESKTOP', isOnDesktop);
  },
  setIsOnTeamWindow({ commit }, isOnTeamWindow) {
    commit('SET_IS_ON_TEAM_WINDOW', isOnTeamWindow);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
