import axios from 'axios';
export const API = axios.create({
  baseURL: `${window.zerotouchChatConfig?.mspUrl ||
    window.zerotouchChatWebChannel?.mspUrl}/api/`,
});
export const setHeader = (value, key = 'Authorization') => {
  API.defaults.headers.common[key] = value;
  API.defaults.headers.common['Content-Type'] = 'application/json';
  API.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};
