<template>
  <div class="row content-box full-height">
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <div class="wizard-body columns content-box small-9 teamsbox">
          <div class="medium-12 columns text-center">
            <div class="teamsapp">
              <img
                class="zerotouchteams"
                :src="'/dashboard/images/integrations/zerotouchchat.png'"
              />
              <h6>ZeroTouch Chat</h6>
            </div>
            <woot-button
              class="deploy-microsoft-teams-button"
              @click="deployZerotouchChatToUsers"
            >
              Deploy ZeroTouch Chat for Microsoft Teams App
            </woot-button>
            <div>
              <span
                v-dompurify-html="
                  $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.MESSAGE')
                "
              />
            </div>
            <div>
              <span
                v-dompurify-html="
                  $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.NOTE')
                "
                class="deployment-time"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INTEGRATION_SETTINGS.MICROSOFT_TEAMS.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [alertMixin, globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  methods: {
    async deployZerotouchChatToUsers() {
      try {
        await this.$store.dispatch(
          'microsoftTeamsApp/deployZeroTouchChatToUsers',
          this.$route.params.accountId
        );
        this.showAlert(
          'ZeroTouch Chat is being deployed! It may take up to 24 hours to deploy to all users'
        );
      } catch (error) {
        this.showAlert(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.teamsapp {
  margin-top: 25px;
}
.zerotouchteams {
  height: 120px;
  width: 124px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}
.deploy-microsoft-teams-button {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}
.deployment-time {
  p {
    font-size: 1.1rem !important;
  }
}
.teamsbox {
  height: 60% !important;
  width: 850px;
}
</style>
