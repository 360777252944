import { frontendURL } from '../../../../helper/URLHelper';

const clients = accountId => ({
  parentNav: 'clients',
  routes: [],
  menuItems: [
    {
      icon: 'client',
      label: 'ALL_CLIENTS',
      key: 'clients',
      toState: frontendURL(`accounts/${accountId}/clients`),
      toolTip: 'Clients List',
      toStateName: 'clients_dashboard',
    },
  ],
});

export default clients;
