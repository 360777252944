<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="add-client-wrapper">
      <woot-modal-header :header-title="'Add Client'" />
      <div class="row modal-body add-client-wrapper">
        <div class="columns medium-12 options-wrapper">
          <span class="label">Client List</span>
          <div>
            <multiselect
              v-model="selectedClient"
              :options="options()"
              track-by="_id"
              label="client_name"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :hide-selected="true"
              :max-height="175"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              placeholder="Select Client"
              open-direction="bottom"
              :loading="uiFlags.isFetchingNewClients"
            >
              <template slot="option" slot-scope="props">
                <span v-if="props.option.addNewClient">
                  <a
                    v-if="currentUser.is_msp_admin"
                    class="add-new-client-link"
                    target="blank"
                    :href="props.option.addNewClient"
                  >
                    Add New Client
                  </a>
                  {{ !currentUser.is_msp_admin ? 'Add New Client' : null }}
                  <p
                    v-if="!currentUser.is_msp_admin"
                    v-tooltip.top-end="{
                      boundariesElement: 'body',
                      content:
                        'Your not an Admin, you cannot create a new client. Please contact your Admin to create a new client',
                    }"
                  >
                    <fluent-icon icon="info" />
                  </p>
                </span>
                <span v-else-if="props.option.$isDisabled" class="info">
                  {{ props.option.client_name }}
                  <p
                    v-tooltip.top-end="{
                      boundariesElement: 'body ',
                      content:
                        'Cannot select this client. This client has already been added to the ZeroTouch Chat page.',
                    }"
                  >
                    <fluent-icon icon="info" />
                  </p>
                </span>
                <span v-else>{{ props.option.client_name }}</span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <woot-button
          variant="smooth"
          color-scheme="dark"
          @click.prevent="onClose"
        >
          Cancel
        </woot-button>
        <woot-button
          :is-disabled="isDisabled()"
          :is-loading="uiFlags.isAdding"
          @click="addClient"
        >
          Add Client
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import ClientAPI from '../../../../api/clients';
export default {
  mixins: [alertMixin],
  data() {
    return {
      show: true,
      name: '',
      selectedClient: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'clients/getUIFlags',
      clientList: 'clients/getNewClients',
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {
    this.$store.dispatch('clients/getNewClients');
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async addClient() {
      try {
        this.$store.dispatch('clients/isAdding', true);
        await ClientAPI.addClients(this.selectedClient);
        this.showAlert('New Client saved!');
        this.$emit('close');
        this.$store.dispatch('clients/get', {});
        window.history.pushState({}, null, `${this.$route.path}?page=1`);
      } catch (error) {
        this.showAlert(error.response.data.message || 'server error');
      } finally {
        this.$store.dispatch('clients/isAdding', false);
      }
    },
    isDisabled() {
      // eslint-disable-next-line no-underscore-dangle
      return !this.selectedClient._id;
    },
    options() {
      return [
        {
          _id: 'df',
          addNewClient: window.zerotouchChatConfig.addClientMspUrl,
          name: 'addNewClient',
          $isDisabled: true,
        },
        ...this.clientList,
      ];
    },
  },
};
</script>
<style lang="scss">
.add-client-wrapper {
  .options-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #80808042;
    border-top: 1px solid #80808042;
  }
  .multiselect {
    width: 40rem;
    margin-bottom: 0 !important;
  }
  .modal-footer {
    gap: 1rem;
    padding: 0.8rem 1rem !important;
  }
  .multiselect--above .multiselect__content-wrapper {
    position: relative !important;
  }
  .page-sub-title {
    position: relative;
    top: -12px;
  }
  .multiselect__tags-wrap {
    overflow-y: scroll;
    max-height: 14rem;
    width: 36rem;
  }
  .label {
    font-weight: 700;
    font-size: 15px;
  }
  .multiselect__element:has(.gray-out) {
    background: var(--disable-color) !important;
  }
  .multiselect__option--disabled > span {
    display: flex !important;
    justify-content: space-between;
  }
  .multiselect__option--disabled:has(a) {
    background-color: white;
  }
  .multiselect__option--disabled {
    pointer-events: none;
  }
  .add-client-wrapper p > svg {
    color: var(--w-500);
    pointer-events: auto;
  }
  .add-new-client-link {
    pointer-events: auto;
  }
}
</style>
